import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Button, Col, Image, Modal, notification, Row } from "antd"
import { useParams } from "react-router-dom"
import { AppApi } from "../../Api/AppApi"
import WarnIcon from "../../resources/images/warn-icon.png"
import ModalAppResetSecret from "./ModalAppResetSecret"

interface Props {
	onVisibleChange: (visible: boolean) => void
	onSuccess: () => void
	handleAllowed: (val: boolean) => void
}

export const ModalConfirmResetSecret = (props: Props) => {
	const { appCode } = useParams()

	const handleCancel = () => {
		props.onVisibleChange(false)
	}

	const handleResetSecret = async () => {
		try {
			const response = await AppApi.resetSecretApp(appCode!)
			notification.success({ message: "Reset thành công !" })
			confirm(response.data)
			props.onSuccess()
		} catch (err: any) {
			const { status } = err.response
				if (status === 403) {
					props.handleAllowed(true)
					props.onVisibleChange(false)
				} else {
					notification.error({
						message: err.message || "Có lỗi xảy ra trong lúc tải trang",
					})
				}

		}
	}

	const confirm = (data: any) => {
		Modal.confirm({
			className: "app-modal app-modal-secret",
			title: "Mã Bảo Mật Secret Key",
			content: <ModalAppResetSecret app={data} />,
			okText: <span>Xác nhận</span>,
			cancelText: <span>Hủy Bỏ</span>,
			okButtonProps: { className: "btn", htmlType: "submit", icon: <CheckOutlined />, type: "default" },
			cancelButtonProps: { className: "btn cancel", type: "default", icon: <CloseOutlined /> },
			centered: true,
			closable: true,
			icon: false,
			width: 500,
		})
		props.onVisibleChange(false)
	}

	return (
		<>
			<Modal
				className="app-modal-confirm"
				title="Reset Secret"
				centered
				onCancel={handleCancel}
				visible={true}
				footer={[
					<Button key="1" onClick={handleCancel} className="btn cancel">
						<CloseOutlined /> Hủy Bỏ
					</Button>,
					<Button key="2" className="btn" onClick={handleResetSecret}>
						<CheckOutlined /> Xác Nhận
					</Button>,
				]}
			>
				<Row align="middle" gutter={[16, 24]}>
					<Col xs={4}>
						<div className="warn-box">
							<Image preview={false} src={WarnIcon} />
						</div>
					</Col>
					<Col xs={20}>
						<b className="font-size-16 line-h-24 ">Chú ý ! </b> <br />
						<p>Mã bảo mật hiện tại của bạn sẽ được làm mới.</p>
					</Col>
					<Col>
						<p>Bạn có chắc chắn muốn thực hiện hành động này không ?</p>
					</Col>
				</Row>
			</Modal>
		</>
	)
}
