import React, { useEffect, useState } from 'react';
import './App.scss';
import AppRoutes from './Routes';
import {
  AppContext,
  AppContextValueInterface,
  defaultAppContextValue,
} from './Context/AppContext';
import localStore from './Util/LocalStore';
import axios from 'axios';
import appConfig from './Config/App';
import { TenantModel } from './Domain/TenantDomain';

const App = () => {
  const [tenant, setTenant] = useState(TenantModel);

  const getContextValue = () => {
    const value: AppContextValueInterface = { ...defaultAppContextValue };
    value.state.tenant = tenant;
    return { ...value };
  };

  const fetchCurrentTenant = () => {
    const tenant = localStore.getJson('tenant');
    if (!tenant) {
      axios({
        url: appConfig.apiUrl + '/tenants/current',
      }).then((response) => {
        setTenant(response.data);
        localStore.setJson('tenant', response.data);
      });
    } else {
      setTenant(tenant);
    }
  };

  useEffect(() => {
    fetchCurrentTenant();
  }, []);

  return (
    <AppContext.Provider value={getContextValue()}>
      <div className="main-body">
        <AppRoutes />
      </div>
    </AppContext.Provider>
  );
};

export default App;
