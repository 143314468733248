import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Badge, Button, Modal, notification, Space, Table, Tag, Typography } from "antd"
import lodash from "lodash"
import React, { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { AppApi } from "../../../Api/AppApi"
import ModalUserCreate from "../../../Component/Modal/ModalUserCreate"
import MemberCriteria from "../../../Criteria/MemberCriteria"
import { USER_STATUS } from "../../../Util/Constants"
interface User {
	id: string
	key: any
	username: string
	email: string
	role: any
	status: string
}

const UserList: React.FC = () => {
	const [loading, setLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [lastUserEditable] = useState<string | null>(null)
	const [isShowModalCreate, setIsShowModalCreate] = useState(false)
	const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)

	const [data, setData] = useState<Array<User>>([])
	const [total, setTotal] = useState(0)

	const [pageSize, setPageSize] = useState(Number(searchParams.get("pageSize")) || 25)

	const { appCode } = useParams()

	const location = useLocation()

	const columns = [
		{
			title: "Username",
			dataIndex: "username",
			key: "username",
			width: "167px",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
			width: "200px",
		},
		{
			title: (
				<>
					Vai trò
					<InfoCircleOutlined className="info-icon" />
				</>
			),
			dataIndex: "role",
			key: "role",
			width: "150px",
		},
		{
			title: "Trạng Thái",
			key: "status",
			width: "150px",
			dataIndex: "status",
			render: (status: any) => getColor(status),
		},
		{
			title: "Tác vụ",
			key: "action",
			width: "150px",
			align: "right" as "right",
			render: (row: any) => (
				<Space>
					<span
						onClick={() => handleRemoveUser(row.id)}
						className="btn-table-delete flex items-center pointer"
					>
						<DeleteOutlined /> Xoá
					</span>
				</Space>
			),
		},
	]

	useEffect(() => {
		const offset = searchParams.get("offset") || 0
		const limit = pageSize
		const sort = searchParams.get("sort") || "createdAt:desc"
		const filter: MemberCriteria = { offset, limit, sort }

		const fetchUserList = async () => {
			setLoading(true)
			try {
				const response: any = await AppApi.filterMember(appCode!, filter)
				const { data } = response

				const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
				const total = parseInt(lodash.get(response, "headers.x-total-count"))
				setCurrentPage(currenPage + 1)
				setPageSize(parseInt(lodash.get(response, "headers.x-page-size")))
				setData(data)
				setTotal(total)
			} catch (e) {}
			setLoading(false)
		}
		fetchUserList()
	}, [appCode, location, searchParams, pageSize])

	const getColor = (status: string) => {
		lodash.get(USER_STATUS, status)
		const { name, color } = lodash.get(USER_STATUS, status)
		return <Badge color={color} text={name} className="badge-table" />
	}

	const handleShowModalCreate = (visible: boolean) => {
		setIsShowModalCreate(visible)
	}

	const handleAppEditSuccess = () => {
		setSearchParams({ t: Math.floor(Math.random() * 1000).toString() })
	}

	const handleChangePage = (page: number) => {
		setSearchParams({
			...lodash.mapValues(
				{ page: page, limit: pageSize, offset: (page - 1) * pageSize },
				lodash.method("toString")
			),
		})
	}

	const handleRemoveUser = (userId: any) => {
		Modal.confirm({
			title: "Bạn có chắc chắn muốn xoá thành viên này khỏi ứng dụng?",
			onOk: () => {
				setLoading(true)
				AppApi.removeMember(appCode!, userId)
					.then(() => {
						notification.success({
							message: "Xoá thành viên thành công",
						})

						setSearchParams({ t: new Date().getTime().toString() })
					})
					.catch(() => {
						notification.error({
							message: "Xoá thành viên thất bại",
						})
					})
					.finally(() => {
						setLoading(false)
					})
			},
			okButtonProps: {
				loading: loading,
				disabled: loading,
			},
			cancelButtonProps: {
				loading: loading,
				disabled: loading,
			},
		})
	}

	return (
		<>
			{isShowModalCreate && (
				<ModalUserCreate onVisibleChange={handleShowModalCreate} onSuccess={handleAppEditSuccess} />
			)}
			<div className="template-box-table">
				<div className="template-box-table-head flex items-center">
					<Typography.Title level={5}>
						Danh Sách Nhân Viên {lastUserEditable}
						<Tag color="#E2FBFA">{data.length}</Tag>
					</Typography.Title>
					<div className="line-divider" />
					<Button className="btn" onClick={() => setIsShowModalCreate(true)}>
						<PlusOutlined />
						Thêm Nhân Viên
					</Button>
				</div>

				<div className="template-box-table-list">
					<Table
						loading={loading}
						rowKey={(record) => record.id}
						columns={columns}
						dataSource={data}
						pagination={{
							current: currentPage,
							total: total,
							pageSize: 25,
							hideOnSinglePage: true,
							onChange: handleChangePage,
						}}
					/>
				</div>
			</div>
		</>
	)
}

export default UserList
