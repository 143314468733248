import { InfoCircleOutlined } from "@ant-design/icons"
import { Badge, Table, Tag, Typography } from "antd"
import lodash from "lodash"
import React, { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { AdminAppApi } from "../../../../Api/AdminAppApi"
import { USER_STATUS } from "../../../../Util/Constants"

interface User {
	id: string
	key: any
	username: string
	email: string
	role: any
	status: string
}

const UserList: React.FC = () => {
	const [loading, setLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [lastUserEditable] = useState<string | null>(null)
	const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
	const [data, setData] = useState<Array<User>>([])
	const [total, setTotal] = useState(0)
	const [pageSize, setPageSize] = useState(Number(searchParams.get("pageSize")) || 25)

	const { appCode } = useParams()

	const location = useLocation()

	const columns = [
		{
			title: "Username",
			dataIndex: "username",
			key: "username",
			width: "16%",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
			width: "20%",
		},
		{
			title: (
				<>
					Vai trò
					<InfoCircleOutlined className="info-icon" />
				</>
			),
			dataIndex: "role",
			key: "role",
			width: "24%",
		},
		{
			title: "Trạng Thái",
			key: "status",
			width: "20%",
			dataIndex: "status",
			render: (status: any) => getColor(status),
		},
	]

	useEffect(() => {
		const fetchUserList = async () => {
			setLoading(true)
			try {
				const response: any = await AdminAppApi.getUsersInApp(appCode!)
				const { data } = response

				const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
				const total = parseInt(lodash.get(response, "headers.x-total-count"))
				setCurrentPage(currenPage + 1)
				setPageSize(parseInt(lodash.get(response, "headers.x-page-size")))
				setData(data)
				setTotal(total)
			} catch (e) {}
			setLoading(false)
		}
		fetchUserList()
	}, [appCode, location])

	const getColor = (status: string) => {
		const { name, color } = lodash.get(USER_STATUS, status)
		return <Badge color={color} text={name} className="badge-table" />
	}

	const handleChangePage = (page: number) => {
		setSearchParams({
			...lodash.mapValues(
				{ page: page, limit: pageSize, offset: (page - 1) * pageSize },
				lodash.method("toString")
			),
		})
	}

	return (
		<div className="template-box-table mg-bt-40">
			<div className="template-box-table-head flex items-center">
				<Typography.Title level={5}>
					Danh Sách Nhân Viên {lastUserEditable}
					<Tag color="#E2FBFA">{data.length}</Tag>
				</Typography.Title>
				<div className="line-divider" />
			</div>

			<div className="template-box-table-list">
				<Table
					loading={loading}
					rowKey={(record) => record.id}
					columns={columns}
					dataSource={data}
					pagination={{
						current: currentPage,
						total: total,
						pageSize: 25,
						hideOnSinglePage: true,
						onChange: handleChangePage,
					}}
				/>
			</div>
		</div>
	)
}

export default UserList
