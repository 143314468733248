export interface AppDomain {
	id: string
	tenant: string
	code: string
	name: string
	avatar: string
	description: string
	secret: string
	maskedSecret: string
	status: string
	owner: string
	createdAt: string
	createdBy: string
	modifiedAt: string
	modifiedBy: string
	version: number
}

export const AppDefault: AppDomain = {
	code: "",
	createdAt: "",
	createdBy: "",
	description: "",
	id: "",
	maskedSecret: "",
	modifiedAt: "",
	modifiedBy: "",
	name: "",
	avatar: "",
	owner: "",
	secret: "",
	status: "",
	tenant: "",
	version: 0,
}
