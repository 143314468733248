import { Button, Table } from "antd"
import { useState } from "react"
import WhiteBox from "../../../Component/Custom/WhiteBox"
import { AppDomain } from "../../../Domain/AppDomain"
import lodash from "lodash"
import moment from "moment"
import { BLACKLIST_STATUS } from "../../../Util/Constants"

interface AppsProps {
	isLoading?: boolean
	data?: Array<AppDomain>
	total: number
	pageSize: number
	currentPage: number
	showModalAppCreate?: () => void
	onChangePage: (page: number, pageSize: number) => void
	onReopenMail: (email: string) => void
}

const DataList: React.FC<AppsProps> = (props) => {
	const { isLoading, total, pageSize, data, currentPage } = props
	const [columns] = useState([
		{
			title: "Email",
			key: "email",
			render: (row: any) => {
				return lodash.get(row, "email")
			},
		},
		{
			title: "Trạng thái",
			key: "status",
			render: (row: any) => {
				return lodash.get(BLACKLIST_STATUS, [lodash.get(row, "status"), "name"])
			},
		},
		{
			title: "Lý do",
			key: "reason",
			render: (row: any) => {
				return lodash.get(row, "reason")
			},
		},
		{
			title: "Thời gian",
			key: "timestamp",
			render: (row: any) => {
				return lodash.has(row, "timestamp") ? moment(row.timestamp).format("DD/MM/YYYY HH:mm") : "--"
			},
		},
		{
			title: "Hành động",
			key: "action",
			render: (row: any) => {
				if (lodash.get(row, "status") === BLACKLIST_STATUS.BLOCKED.code) {
					return (
						<Button type={"link"} onClick={() => handleReopenMail(lodash.get(row, "email"))}>
							Mở lại
						</Button>
					)
				}
				return "--"
			},
		},
	])

	const handleReopenMail = (email: string) => {
		props.onReopenMail(email)
	}

	const handleChangePage = (page: number, pageSize: number) => {
		props.onChangePage(page, pageSize)
	}

	const showTotal = (total: number) => {
		return (
			<span>
				<b>{total}</b> bản ghi
			</span>
		)
	}

	return (
		<WhiteBox className="pd-24 app-list">
			<div className="table-responsive">
				<Table
					rowKey={(record: AppDomain) => record.id}
					columns={columns}
					dataSource={data}
					loading={isLoading}
					scroll={{ y: 500, x: 1024 }}
					pagination={{
						current: currentPage,
						total: total,
						pageSize: pageSize,
						onChange: handleChangePage,
						showTotal: showTotal,
					}}
				/>
			</div>
		</WhiteBox>
	)
}

export default DataList
