import { HomeFilled, SendOutlined } from "@ant-design/icons"
import React from "react"
import { Link } from "react-router-dom"

interface Menu {
	icon: JSX.Element
	title: string
	key: string
	url: string
}

interface AppMenuProps {
	appCode?: string
	currentKey: string
}

const AppMenu: React.FC<AppMenuProps> = (props: AppMenuProps) => {
	const { currentKey } = props

	const listMenu: Menu[] = [
		{
			icon: <HomeFilled />,
			title: "Tổng quan",
			key: "OVER_VIEW",
			url: `/admin/apps/${props.appCode}`,
		},
		{
			icon: <SendOutlined />,
			title: "Danh sách người gửi",
			key: "SENDERS",
			url: `/admin/apps/${props.appCode}/senders`,
		},
	]

	return (
		<>
			<div className="template-wrapper-tabs">
				<div className="template-box template-tabs">
					{listMenu.map(({ title, key, icon, url }) => (
						<Link to={url} className={`tab-item ${key === currentKey ? "active" : ""}`} key={key}>
							<div className="tab-item-icon">{icon}</div>
							<div className="tab-item-text">{title}</div>
						</Link>
					))}
				</div>
			</div>
		</>
	)
}

export default AppMenu
