import { CreateMailTemplateCommand } from "../Command/CreateMailTemplateCommand"
import UpdateMailTemplateCommand from "../Command/UpdateMailTemplateCommand"
import MailTemplateCriteria from "../Criteria/MailTemplateCriteria"
import apiClient from "../Util/ApiClient"

export class MailTemplateApi {
	static filter(appCode: string, criteria: MailTemplateCriteria) {
		return apiClient.get(`/apps/${appCode}/mail-templates`, {
			params: criteria,
		})
	}

	static findMailTemplateByCode(code: string, appCode: string) {
		return apiClient.get(`/apps/${appCode}/mail-templates/${code}`)
	}

	static createMailTemplate = (appCode: string, command: CreateMailTemplateCommand) => {
		return apiClient.post(`/apps/${appCode}/mail-templates`, command)
	}

	static updateMailTemplate = (appCode: string, code: string, command: UpdateMailTemplateCommand) => {
		return apiClient.patch(`/apps/${appCode}/mail-templates/${code}`, command)
	}

	static removeTemplate = (code: string, templateId: string) => {
		return apiClient.delete(`/apps/${code}/mail-templates/${templateId}`)
	}
	static getMailTemplatesAutocomplete(appCode: string, criteria: MailTemplateCriteria) {
		return apiClient.get(`/apps/${appCode}/mail-templates/autocomplete`,{
			params:criteria,
		})
	}
}
