import React, {useState} from "react";
import moment from "moment";
import {useSearchParams} from "react-router-dom";
import lodash from "lodash";
import {Button, DatePicker, Form, Input, Row, Select} from "antd";
import {RedoOutlined, SearchOutlined} from "@ant-design/icons";

const RangePicker = DatePicker.RangePicker
const beginDateFormat = "YYYY-MM-DD HH:mm:00"
const endDateFormat = "YYYY-MM-DD HH:mm:59"
const {Option} = Select;
type MailStatus = {
    code?:string,
    name?:string,
    color?:string
}

type MailSendFilterProps = {
    mailStatuses:MailStatus[],
}


export function MailSendFilter(props: MailSendFilterProps) {
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageSize,] = useState(Number(searchParams.get("pageSize")) || 25);

    const {mailStatuses} = props;

    const getFormState = () => {
        let timestampFrom = form.getFieldValue('requestRangeTime') && form.getFieldValue('requestRangeTime')[0]
            ? moment(form.getFieldValue('requestRangeTime')[0].format(beginDateFormat)).toISOString() : null ;
        let timestampTo = form.getFieldValue('requestRangeTime') && form.getFieldValue('requestRangeTime')[1]
            ? moment(form.getFieldValue('requestRangeTime')[1].format(beginDateFormat)).toISOString(): null;
        return {...form.getFieldsValue(true),timestampFrom,timestampTo};
    }

    const handleFilter = () => {
        const params = {
            ...Object.fromEntries(searchParams.entries()),
            ...getFormState()
        }

        lodash.unset(params, 'requestRangeTime')

        setSearchParams({
            ...lodash.omitBy(params, v => !v),
            limit: pageSize.toString(),
            offset: "0"
        })
    }

    const handleChangeStatus = (value:any) => {
        const params = Object.fromEntries(searchParams.entries());
        params.status = value;
        setSearchParams({
            ...lodash.omitBy(params, v => !v),
            limit: pageSize.toString(),
            offset: "0"
        })
    }

    const handleChangeRangePicker = (field:string,values:any) => {
        let timestampFrom = searchParams.get("timestampFrom") || "";
        let timestampTo = searchParams.get("timestampTo") || ""
        if (field === "requestTime") {
            timestampFrom =  values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : ""
            timestampTo = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : ""
        }
        let params = Object.fromEntries(searchParams.entries());
        params.timestampFrom = timestampFrom
        params.timestampTo = timestampTo;

        setSearchParams({
            ...lodash.omitBy(params, v => !v),
            limit: pageSize.toString(),
            offset: "0"
        })
    }

    const clearFilter = () => {
        form.setFieldsValue({
            from: '',
            recipient: '',
            subject: '',
            status: null,
            requestRangeTime: []
        })
        setSearchParams({})
    }

    return (
        <div className={'mg-t-5'}>
            <Form
                form={form}
                layout={'horizontal'}
                className={'mail-send__form mg-bt-16 template-temp justify-between items-center'}
                onFinish={handleFilter}
                initialValues={{
                    from:searchParams.get('from') ,
                    recipient:searchParams.get('recipient'),
                    subject:searchParams.get('subject'),
                    status: searchParams.get('status'),
                    requestRangeTime: [
                        searchParams.get('timestampFrom') ? moment(searchParams.get('timestampFrom')): null,
                        searchParams.get('timestampTo') ? moment(searchParams.get('timestampTo')) : null,
                    ]
                }}
                labelAlign={"left"}
            >
                <Row className={'d-flex template-temp-input'}>
                    <Form.Item className={''} name={'from'} label={'From'}>
                        <Input placeholder={"From"}
                               className={'width-100pc'}
                        />
                    </Form.Item>
                    <Form.Item className={''} name={'recipient'} label={'To/Cc/Bcc'}>
                        <Input placeholder={"To/Cc/Bcc"}/>
                    </Form.Item>
                    <Form.Item className={''} name={'subject'} label={'Tiêu đề'}>
                        <Input placeholder={"Nhập nội dung"}/>
                    </Form.Item>
                    <Form.Item className={''} name={'status'} label={'Trạng thái'}>
                        <Select
                            className={'ant-select-custom'}
                            placeholder={"Chọn trạng thái"}
                            onChange={(value:any)=> handleChangeStatus(value)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                                option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                            }
                        >
                            {mailStatuses.map((item: any) => (
                                <Option key={item.code} value={item.code}>{item.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"Thời gian request"} name={'requestRangeTime'}>
                        <RangePicker
                            className={"width-100pc"}
                            placeholder={["Từ", "Đến"]}
                            format={"DD/MM/YYYY HH:mm"}
                            showTime={true}
                            onChange={(value) => handleChangeRangePicker("requestTime", value)}
                        />
                    </Form.Item>
                </Row>

                <Row className={'mg-t-5'} justify={'space-between'}>
                    <div className="button-clear pointer">
                        <span onClick={clearFilter}>
                            <RedoOutlined/> Làm mới bộ lọc
                        </span>
                    </div>
                    <div className="button-filter">
                        <Button className="btn" htmlType="submit" icon={<SearchOutlined/>}>
                            Tìm Kiếm
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    );
}
