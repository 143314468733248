import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Button, Form, Input, notification, Spin, Typography } from "antd"
import lodash from "lodash"
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AppApi } from "../../../../../Api/AppApi"
import { MailTemplateApi } from "../../../../../Api/MailTemplateApi"
import { AccessDenied } from "../../../../../Component/AccessDenied"
import TinyMCE from "../../../../../Component/Custom/TinyMCE"
import DefaultLayout from "../../../../../Component/Layout/Default"

const { Title } = Typography

export const TemplateEmailAdd = () => {
	const [form] = Form.useForm()
	const { appCode } = useParams()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [accessDenied, setAccessDenied] = useState(false)
	const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)

	useEffect(() => {
		const fetchAppDetail = async () => {
			setIsLoading(true)
			try {
				await AppApi.getAppByCode(appCode!)
				setIsLoading(false)
			} catch (err: any) {
				const { status } = err.response
				if (status === 403) {
					setAccessDenied(true)
				} else {
					notification.error({
						message: err.message || "Có lỗi xảy ra trong lúc tải trang",
					})
				}

				setIsLoading(false)
			}
		}

		fetchAppDetail()
	}, [appCode])

	const onFinish = async (values: any) => {
		try {
			await form.validateFields()
			setIsLoading(true)
			await MailTemplateApi.createMailTemplate(appCode!, values)
			form.resetFields()
			notification.success({ message: "Tạo mới thành công !" })
			setIsLoading(false)
			navigate(`/apps/${appCode}/templates`)
		} catch (err: any) {
			if (lodash.get(err, "response.status") === 400) {
				const { title } = err.response.data
				if (title === "mail_template_exist") {
					notification.error({ message: "Template đã tồn tại!" })
				} else {
					notification.error({ message: err.message || "Đã xảy ra lỗi !" })
				}
			} else {
				notification.error({ message: err.message || "Đã xảy ra lỗi !" })
			}
			setIsLoading(false)
		}
	}

	const onFieldsChange = () => {
		setDisabledSubmitButton(form.getFieldsError().some((field) => field.errors.length > 0))
	}

	if (accessDenied) {
		return <AccessDenied />
	}

	return (
		<DefaultLayout title="Template - M29">
			<Spin spinning={isLoading}>
				<div className="main-content template">
					<div className="template-box template-add">
						<Link to={`/apps/${appCode}/templates`} className="template-add-leave">
							<ArrowLeftOutlined /> Trở về Danh Sách Template
						</Link>
						<Title level={5} className="template-add-title">
							Template Thư Mới
						</Title>
						<Form
							form={form}
							name="basic"
							labelCol={{ span: 6 }}
							wrapperCol={{ span: 12 }}
							initialValues={{ remember: true }}
							autoComplete="off"
							onFinish={onFinish}
							onFieldsChange={onFieldsChange}
						>
							<Form.Item
								className="template-add-leave"
								label={<span className="need-required">Mã Template :</span>}
								name="code"
								labelAlign="left"
								rules={[
									{
										required: true,
										message: "Vui lòng nhập mã !",
									},
									{
										whitespace: true,
										message: "Mã không hợp lệ",
									},
								]}
							>
								<Input autoFocus={true} placeholder="Type here..." />
							</Form.Item>
							<Form.Item className="template-add-leave" label={<span>Tên Template :</span>} name="name">
								<Input placeholder="Type here..." />
							</Form.Item>
							<Form.Item className="template-add-leave" label={<span>Tiêu đề thư :</span>} name="subject">
								<Input placeholder="Type here..." />
							</Form.Item>
							<Form.Item
								className="template-add-leave template-add-editor"
								label={<span>Nội dung thư :</span>}
								name="body"
							>
								<TinyMCE />
							</Form.Item>
							<div className="d-plex justify-end template-add-btn">
								<Button className="btn cancel" onClick={() => navigate(-1)} icon={<CloseOutlined />}>
									Hủy Bỏ
								</Button>
								<Button
									className="btn"
									disabled={disabledSubmitButton}
									htmlType="submit"
									icon={<CheckOutlined />}
								>
									Xác Nhận
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</Spin>
		</DefaultLayout>
	)
}
