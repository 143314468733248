import React from "react"
import { Button, Col, Form, Image, Modal, Row } from "antd"
import WarnIcon from "../../resources/images/warn-icon.png"
import { CheckOutlined, CloseOutlined, CopyFilled, CopyOutlined } from "@ant-design/icons"
import Paragraph from "antd/lib/typography/Paragraph"
import { AppDomain } from "../../Domain/AppDomain"

const { Item } = Form

export interface Props {
	onVisibleChange: (visible: boolean) => void

	app: AppDomain
}

const ModalAppSecret: React.FC<Props> = (props: Props) => {
	const footerCustom = [
		<Button
			className="btn cancel"
			key="cancel"
			htmlType="button"
			icon={<CloseOutlined />}
			onClick={() => props.onVisibleChange(false)}
		>
			Hủy Bỏ
		</Button>,
		<Button
			key="ok"
			className="btn"
			htmlType="submit"
			icon={<CheckOutlined />}
			onClick={() => {
				props.onVisibleChange(false)
			}}
		>
			Xác nhận
		</Button>,
	]

	return (
		<Modal
			title="Mã Bảo Mật Secret Key"
			visible={true}
			centered
			onCancel={() => props.onVisibleChange(false)}
			width={500}
			okText="Xác nhận"
			cancelText="Huỷ bỏ"
			closable={true}
			footer={footerCustom}
			className="app-modal"
		>
			<Row align="middle" gutter={[16, 24]}>
				<Col xs={4}>
					<div className="warn-box">
						<Image preview={false} src={WarnIcon} />
					</div>
				</Col>
				<Col xs={20}>
					<b className="font-size-16 line-h-24 ">Chú ý!</b>
					<br />
					'Mã bảo mật Secret Key&nbsp;
					<b>rất quan trọng và chỉ cấp một lần</b>. Bạn vui lòng lưu lại Secret Key vào một nơi an toàn!'
				</Col>
				<Col xs={24}>
					<Form>
						<Item label={<span>Mã Secret Key :</span>}>
							<Paragraph
								className="secret-key"
								copyable={{
									text: props.app.secret,
									icon: [
										<CopyOutlined key="copy-icon" className="copyable" />,
										<CopyFilled key="copied-icon" />,
									],
								}}
							>
								{props.app.secret}
							</Paragraph>
						</Item>
					</Form>
				</Col>
			</Row>
		</Modal>
	)
}

export default ModalAppSecret
