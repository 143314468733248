type Status = {
	name: string
	color: string
}

export const APP_STATUS: { [id: string]: Status } = {
	ACTIVE: { name: "Đang hoạt động", color: "#52C41A" },
	DISABLED: { name: "Ngừng hoạt động", color: "#D9D9D9" },
	INACTIVE: { name: "Chưa kích hoạt", color: "#FAAD14" },
}

export const APP_USER_ROLE: { [id: string]: string } = {
	staff: "Nhân viên",
	manager: "Quản lý",
	owner: "Chủ sở hữu",
	cadres: "Cán bộ",
}

export const APP_ACTIONS: { [id: string]: string } = {
	ACTIVE: "Kích hoạt",
	OFF: "Tắt",
	ON: "Bật",
}

export const USER_STATUS: { [id: string]: Status } = {
	ACTIVE: { name: "Bình thường", color: "#52C41A" },
	INACTIVE: { name: "Chờ xác nhận", color: "#D9D9D9" },
}

export const BLACKLIST_STATUS = {
	BLOCKED: { code: "BLOCKED", name: "Đã chặn" },
	OPENED: { code: "OPENED", name: "Đã mở" },
}

export const MAIL_STATUS = {
	unsent: { name: "unsent", color: "#FAAD14" },
	sent: { name: "sent", color: "#1A9DC4" },
	delivery: { name: "delivery", color: "#52C41A"},
	negative_complaint: {name: "negative_complaint", color: "#ccc"},
	positive_complaint: {name: "positive_complaint", color: "#ccc"},
	hard_bounce: {name: "hard_bounce", color: "#ccc"},
	soft_bounce: {name: "soft_bounce", color: "#ccc"},
	application_mistake: {name: "application_mistake", color: "#ccc"}
}

export const SENDER_STATUS: { [id: string]: Status } = {
	VERIFIED: { name: "Đã xác nhận", color: "#52C41A" },
	DISABLED: { name: "Ngừng hoạt động", color: "#D9D9D9" },
	PENDING: { name: "Chờ xử lý", color: "#FAAD14" },
}


export const VERIFY_MAIL_STATUS:  { [id: string]: string }= {
	SENDER_NOT_FOUND:  'Người gửi không tồn tại.',
	DISABLED_SENDER:  'Người gửi đang ở trạng thái ngưng hoạt động. Không thể thực hiện thao tác này.',
	PENDING_VERIFICATION:  'Email xác thực đã được gửi tới địa chỉ mail của Sender. Chờ xác nhận từ người gửi mail.',
	EXPIRED_VERIFICATION:  'Yêu cầu hết hiệu lực.',
	VERIFICATION_TEMPORARILY_FAILED:  'Quá trình gửi mail xác thực xảy ra lỗi. Vui lòng thử lại.',
	VERIFICATION_NOT_STARTED:  'Mail xác nhận chưa được gửi tới địa chỉ mail của người gửi',
	UNKNOWN_SDK_VERSION:  'Lỗi hệ thống!',
	SENDER_NOT_IDENTIFIED:  'Sender này chưa được xác thực.',
}

export const CREATE_SENDER_ERROR : {[id: string]: string} = {
	SENDER_EXIST: 'Địa chỉ mail người gửi đã tồn tại'
}
