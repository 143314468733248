import {InfoCircleOutlined, PlusOutlined, RedoOutlined, SearchOutlined} from "@ant-design/icons"
import {Badge, Button, Form, Image, Input, notification, Space, Table, Tag, Tooltip, Typography} from "antd"
import {ColumnsType} from "antd/es/table"
import lodash from "lodash"
import React, {useEffect, useState} from "react"
import {useParams, useSearchParams} from "react-router-dom"
import {AdminAppApi} from "../../../../../Api/AdminAppApi"
import ModalAppCreateSender from "../../../../../Component/Modal/ModalAppCreateSender"
import SenderCriteria from "../../../../../Criteria/SenderCriteria"
import SenderDomain from "../../../../../Domain/SenderDomain"
import IconDrawFailedImg from "../../../../../resources/images/Drawer-Fail.png"
import {SENDER_STATUS, VERIFY_MAIL_STATUS} from "../../../../../Util/Constants"
import {getCleanFormFilter} from "../../../../../Util/Utils"

const getColor = (status: string) => {
	const { name, color } = lodash.get(SENDER_STATUS, status)
	if(status === 'PENDING') {
		return (
			<Tooltip title="Vui lòng kiểm tra hòm thư để xem email yêu cầu xác thực và click vào link xác thực trong email">
				<span className="flex items-center gap-6">
					<InfoCircleOutlined className="pending-color" />
					<span>{name}</span>
				</span>
				
			</Tooltip>
		)	
	}
	return <Badge color={color} text={name} />
}

const SenderList: React.FC = () => {
	const [form] = Form.useForm()
	const [searchParams, setSearchParams] = useSearchParams()

	const [isLoading, setIsLoading] = useState(true)
	const [senderList, setSenderList] = useState<Array<SenderDomain>>([])
	const [pageSize, setPageSize] = useState(Number(searchParams.get("pageSize")) || 25)
	const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
	const [total, setTotal] = useState(0)
	const [visibleModalAddSender, setVisibleModalAddSender] = useState(false)
	const { appCode } = useParams()

	const columns: ColumnsType<SenderDomain> = [
		{
			title: "Địa chỉ mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Trạng thái",
			dataIndex: "status",
			key: "status",
			render: (status: any) => getColor(status),
		},
		{
			title: "Thao tác",
			dataIndex: "",
			key: "action",
			align: "right",
			render: (record) => {
				const { status, code } = record
				return (
					<Space>
						{status === "PENDING" ? (
							<>
								<span
									onClick={() => handleCompleteVerifyMailByUser(code)}
									className="type-link flex items-center pointer"
								>
									Xác thực
								</span>
								<div className="line-1"></div>
								<span
									onClick={() => handleResendVerifyMail(code)}
									className="type-link flex items-center pointer"
								>
									Gửi lại
								</span>
							</>
						) : '- - -'}
					</Space>
				)
			},
		},
	]

	useEffect(() => {
		if (searchParams.get("name")) form.setFieldsValue({ name: searchParams.get("name") })
		if (searchParams.get("email")) form.setFieldsValue({ status: searchParams.get("email") })
		const filter: SenderCriteria = {
			sort: searchParams.get("sort") || "createdAt:desc",
			email: searchParams.get("email") || "",
			name: searchParams.get("name") || "",
			offset: Number(searchParams.get("offset")) || 0,
			limit: pageSize,
		}
		const fetchSenders = async () => {
			try {
				setIsLoading(true)
				const response: any = await AdminAppApi.filterSenders(appCode!, filter)
				const { data } = response
				const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
				const total = parseInt(lodash.get(response, "headers.x-total-count"))

				setSenderList(data)
				setCurrentPage(currenPage + 1)
				setPageSize(parseInt(lodash.get(response, "headers.x-page-size")))
				setTotal(total)
				setIsLoading(false)
			} catch (err: any) {
				setIsLoading(false)
			}
		}

		fetchSenders()
		window.scrollTo({ top: 0, behavior: "smooth" })
	}, [appCode, searchParams, form, pageSize])

	const localeCustom = {
		emptyText: (
			<>
				<div className="mg-bt-8">
					<div>
						<Image preview={false} src={IconDrawFailedImg} />
					</div>
					<div>
						<span>Không có dữ liệu.</span>
					</div>
				</div>
			</>
		),
	}

	const handleModalAddSenderVisible = (visible: boolean) => {
		setVisibleModalAddSender(visible)
	}

	const handleAppAddSenderSuccess = () => {
		setSearchParams({ t: Math.floor(Math.random() * 1000).toString() })
	}

	const onFinish = () => {
		setSearchParams(getCleanFormFilter(form.getFieldsValue(true)))
	}

	const handleChangePage = (page: number) => {
		const params: { [key: string]: any } = {
			page: page,
			limit: pageSize,
			offset: (page - 1) * pageSize,
			name: searchParams.get("name") || "",
			email: searchParams.get("email") || "",
		}
		const keyOfItemEmpty = Object.keys(params).filter(
			(key) => lodash.isEmpty(params[key]) && !lodash.includes(["page", "limit", "offset"], key)
		)
		setSearchParams({
			...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
		})
	}

	const clearFilter = () => {
		form.resetFields()
		setSearchParams({})
	}

	const onShowSizeChange = (current: any, pageSize: any) => {
		setPageSize(pageSize)
	}

	const handleCompleteVerifyMailByUser = async (code: string) => {
		try {
			setIsLoading(true)
			await AdminAppApi.verifyMailByUser(appCode!, code)
			notification.success({
				message: "Gửi yêu cầu thành công !",
			})
			setSearchParams({success: Math.floor(Math.random() * 1000).toString()})
			setIsLoading(false)
		} catch (err: any) {
			const title = lodash.get(err.response, 'data.title').toUpperCase()
			notification.error({ message: VERIFY_MAIL_STATUS[title] || "Đã xảy ra lỗi !" })
			setIsLoading(false)
		}
	}

	const handleResendVerifyMail = async (code: string) => {
		try {
			setIsLoading(true)
			await AdminAppApi.resendVerification(appCode!, code)
			notification.success({
				message: "Gửi yêu cầu thành công !",
			})
			setSearchParams({resend: Math.floor(Math.random() * 1000).toString()})
			setIsLoading(false)
		} catch (err: any) {
			notification.error({ message: "Đã xảy ra lỗi !" })
			setIsLoading(false)
		}
	}

	return (
		<>
			{visibleModalAddSender && (
				<ModalAppCreateSender onVisibleChange={handleModalAddSenderVisible} onSuccess={handleAppAddSenderSuccess} />
			)}
			<div className="template-box">
				<div className="template-box-table">
					<div className="template-box-table-head mg-bt-16">
						<Typography.Title level={5}>
							Danh Sách người gửi
							<Tag color="#E2FBFA">{total}</Tag>
						</Typography.Title>
						<div className="line-divider"/>

						<Button className="btn active" onClick={() => handleModalAddSenderVisible(true)}>
							<PlusOutlined />
							Thêm sender
						</Button>
					</div>
					<Form
						form={form}
						name="form_filter_sender"
						layout="inline"
						onFinish={onFinish}
						className="admin mg-bt-16 template-temp d-plex justify-between"
						initialValues={{ name: "", email: "" }}
						colon={true}
					>
						<div className="d-plex template-temp-input">
							<Form.Item label="Địa chỉ mail:" name="email">
								<Input placeholder="Tìm theo địa chỉ mail..." suffix={<SearchOutlined />} />
							</Form.Item>
						</div>
						<div className="user-actions">
							<Form.Item className="template-temp-input-clear user-actions__clear">
								<span onClick={clearFilter}>
									<RedoOutlined /> Làm mới bộ lọc
								</span>
							</Form.Item>
							<Form.Item className="template-temp-input-search user-actions__search">
								<Button className="btn" htmlType="submit" icon={<SearchOutlined />}>
									Tìm Kiếm
								</Button>
							</Form.Item>
						</div>
					</Form>
					<div className="template-box-table-list">
						<Table
							rowKey={(record: any) => record.id}
							columns={columns}
							dataSource={senderList}
							loading={isLoading}
							locale={localeCustom}
							pagination={{
								current: currentPage,
								total: total,
								pageSize: pageSize,
								hideOnSinglePage: true,
								onChange: handleChangePage,
								onShowSizeChange: onShowSizeChange,
							}}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default SenderList
