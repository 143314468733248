import {Editor} from "@tinymce/tinymce-react"
import React from "react"
import appConfig from "../../Config/App"

interface TinyMCEProps {
	value?: any
	onChange?: (val: any) => void
}

const TinyMCE: React.FC<TinyMCEProps> = ({ value, onChange }) => {
	const handleChangeEditor = (content: any) => {
		triggerChange(content)
	}
	const triggerChange = (changedValue: any) => {
		onChange?.(changedValue)
	}
	return (
		<Editor
			apiKey={appConfig.tinyMCEKey}
			init={{
				height: 330,
				branding: false,
				menubar: false,
				plugins:
					"print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons code",
				toolbar:
					"undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignright aligncenter alignleft | bullist numlist | blockquote | strikethrough | forecolor | removeformat | preview | table | code",
				content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
				invalid_elements: "script,iframe",
				extended_valid_elements : 'a[th*|*],abbr[th*|*],address[th*|*],area[th*|*],article[th*|*],aside[th*|*],audio[th*|*],b[th*|*],bdi[th*|*],bdo[th*|*],blockquote[th*|*],body[th*|*],br[th*|*],button[th*|*],canvas[th*|*],caption[th*|*],cite[th*|*],code[th*|*],col[th*|*],colgroup[th*|*],command[th*|*],datalist[th*|*],dd[th*|*],del[th*|*],details[th*|*],dfn[th*|*],div[th*|*],dl[th*|*],dt[th*|*],em[th*|*],embed[th*|*],fieldset[th*|*],figcaption[th*|*],figure[th*|*],footer[th*|*],form[th*|*],h1[th*|*],h2[th*|*],h3[th*|*],h4[th*|*],h5[th*|*],h6[th*|*],header[th*|*],hr[th*|*],html[th*|*],i[th*|*],iframe[th*|*],img[th*|*],input[th*|*],ins[th*|*],kbd[th*|*],keygen[th*|*],label[th*|*],legend[th*|*],li[th*|*],main[th*|*],map[th*|*],mark[th*|*],menu[th*|*],meter[th*|*],nav[th*|*],object[th*|*],ol[th*|*],optgroup[th*|*],option[th*|*],output[th*|*],p[th*|*],param[th*|*],pre[th*|*],progress[th*|*],q[th*|*],rp[th*|*],rt[th*|*],ruby[th*|*],s[th*|*],samp[th*|*],section[th*|*],select[th*|*],small[th*|*],source[th*|*],span[th*|*],strong[th*|*],sub[th*|*],summary[th*|*],sup[th*|*],table[th*|*],tbody[th*|*],td[th*|*],textarea[th*|*],tfoot[th*|*],th[th*|*],thead[th*|*],time[th*|*],tr[th*|*],track[th*|*],u[th*|*],ul[th*|*],var[th*|*],video[th*|*],wbr[th*|*]'
			}}
			onEditorChange={handleChangeEditor}
			value={value}
		/>
	)
}

export default TinyMCE
