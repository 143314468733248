import React, {Component} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import {AuthenticateRoute} from "./Component/AuthenticateRoute"
import App from "./Screen/App"

import {AppDetail} from "./Screen/App/Detail"
import {TemplateEmailAdd} from "./Screen/App/Detail/MailTemplate/Add"
import {TemplateEmailUpdate} from "./Screen/App/Detail/MailTemplate/Update"
import {Authentication} from "./Screen/Authentication"
import {Home} from "./Screen/Home"
import {Login} from "./Screen/Login"
import {Logout} from "./Screen/Logout"
import {Notfound} from "./Component/Notfound"
import AdminApp from "./Screen/Admin/App"
import BlackList from "./Screen/Admin/BlackList"
import AdminAppDetail from "./Screen/Admin/App/Detail"
import {MailSend} from "./Screen/App/Detail/Send";
import {MailRequest} from "./Screen/App/Detail/MailRequest";
import MailTemplate from "./Screen/App/Detail/MailTemplate"

export default class AppRoutes extends Component {
	render() {
		return (
			<BrowserRouter basename={"/"}>
				<Routes>
					<Route
						path="/"
						element={
							<AuthenticateRoute>
								<Home />
							</AuthenticateRoute>
						}
					/>
					<Route
						path={"/apps"}
						element={
							<AuthenticateRoute>
								<App />
							</AuthenticateRoute>
						}
					/>
					<Route
						path={"/admin/apps"}
						element={
							<AuthenticateRoute>
								<AdminApp />
							</AuthenticateRoute>
						}
					/>
					<Route
						path={"/admin/blacklists"}
						element={
							<AuthenticateRoute>
								<BlackList />
							</AuthenticateRoute>
						}
					/>
					<Route
						path="/admin/apps/:appCode"
						element={
							<AuthenticateRoute>
								<AdminAppDetail />
							</AuthenticateRoute>
						}
					>
						<Route
							path=":tab"
							element={
								<AuthenticateRoute>
									<AppDetail />
								</AuthenticateRoute>
							}
						/>
					</Route>
					<Route
						path="/apps/:appCode"
						element={
							<AuthenticateRoute>
								<AppDetail />
							</AuthenticateRoute>
						}
					/>
					<Route
						path="/apps/:appCode/templates"
						element={
							<AuthenticateRoute>
								<MailTemplate />
							</AuthenticateRoute>
						}
					/>
					<Route
						path="/apps/:appCode/templates/:code/edit"
						element={
							<AuthenticateRoute>
								<TemplateEmailUpdate />
							</AuthenticateRoute>
						}
					/>

					<Route
						path="/apps/:appCode/templates/add"
						element={
							<AuthenticateRoute>
								<TemplateEmailAdd />
							</AuthenticateRoute>
						}
					/>

					<Route
						path="/apps/:appCode/mail-requests"
						element={
							<AuthenticateRoute>
								<MailRequest />
							</AuthenticateRoute>
						}
					/>

					<Route
						path="/apps/:appCode/mail-requests/:mailRequestId/mail-sends"
						element={
							<AuthenticateRoute>
								<MailSend />
							</AuthenticateRoute>
						}
					/>

					<Route path={"/login"} element={<Login />} />
					<Route path={"/logout"} element={<Logout />} />
					<Route path={"/authentication"} element={<Authentication />} />
					<Route path={"*"} element={<Notfound />} />
				</Routes>
			</BrowserRouter>
		)
	}
}
