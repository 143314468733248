import React from "react"
import { AppDomain } from "../../../../Domain/AppDomain"
import { AppInfo } from "./AppInfo"
import UserList from "./UserList"

interface Props {
	app: AppDomain
	loading: boolean
	handleAllowed: (val: boolean) => void
}

const AppOverview: React.FC<Props> = (props) => {
	return (
		<div className="template-box">
			<AppInfo app={props.app} loading={props.loading} handleAllowed={props.handleAllowed}/>
			<UserList />
		</div>
	)
}

export default AppOverview
