import React from "react"
import { Link } from "react-router-dom"
import DefaultLayout from "../Layout/Default"
import imageError from "../../resources/images/image-404.png"
import { Button } from "antd"
export const Notfound = () => {
	return (
		<DefaultLayout>
			<div className="error-page flex items-center flex-col">
				<div>
					<img src={imageError} alt="" />
				</div>

				<div className="error-content">
					<h3>ERROR 404!</h3>
					<p>
						Xin lỗi, trang hiện tại không khả dụng! Liên kết của bạn đã bị hỏng hoặc đường dẫn đã biến mất!
					</p>
					<Link to={"/"}>
						<Button className="btn">Trở Về Trang Chủ</Button>
					</Link>
				</div>
			</div>
		</DefaultLayout>
	)
}
