import { notification } from "antd"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { AdminAppApi } from "../../../../Api/AdminAppApi"
import { AccessDenied } from "../../../../Component/AccessDenied"
import DefaultLayout from "../../../../Component/Layout/Default"
import { AppDefault, AppDomain } from "../../../../Domain/AppDomain"
import AppMenu from "./AppMenu"
import AppOverview from "./AppOverview"
import SenderList from "./Sender"
import lodash from 'lodash'

const getCurrentKey = (appCode: any) => {
	switch (window.location.pathname) {
		case `/admin/apps/${appCode}/templates`:
			return "TEMPLATES"
		case `/admin/apps/${appCode}/senders`:
			return "SENDERS"
		default:
			return "OVER_VIEW"
	}
}

export const AppDetail: React.FC = () => {
	const { appCode } = useParams()
	const [notAllowedError, setNotAllowedError] = useState<boolean | null>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [app, setApp] = useState<AppDomain>(AppDefault)
	const location = useLocation()
	useEffect(() => {
		const fetchAppDetail = async () => {
			setIsLoading(true)
			try {
				const response = await AdminAppApi.getAppByCode(appCode!)
				setApp(response.data)
				setIsLoading(false)
			} catch (err: any) {
				const { status } = err.response
				if (status === 403) {
					setNotAllowedError(true)
				}  
				if(lodash.get(err.response, 'status') !== 401){
					notification.error({ message: lodash.get(err.response, 'title') || "Đã có lỗi xảy ra !" })
				}
				setIsLoading(false)
			}
		}

		fetchAppDetail()

		return () => {
			setNotAllowedError(null)
		}
	}, [appCode, location])

	if (notAllowedError) {
		return <AccessDenied />
	}

	const currentKey = getCurrentKey(appCode)

	return (
		<div className="app__detail">
			<DefaultLayout
				title={`${
					app.name && app.name.length > 40 ? `${app.name.substring(0, 15)}` : app.name
				} - Chi tiết ứng dụng - M29`}
			>
				<div className="main-content template">
					<AppMenu appCode={appCode} currentKey={currentKey} />
					{currentKey === "OVER_VIEW" && <AppOverview app={app} loading={isLoading} handleAllowed={(val:boolean) => setNotAllowedError(val)}/>}
					{currentKey === "SENDERS" && <SenderList />}
				</div>
			</DefaultLayout>
		</div>
	)
}

export default AppDetail
