import DefaultLayout from "../../../../Component/Layout/Default";
import AppMenu from "../AppMenu";
import React, {useEffect, useState} from "react";
import {Link, useLocation, useParams, useSearchParams} from "react-router-dom";
import {AppDefault, AppDomain} from "../../../../Domain/AppDomain";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {AppApi} from "../../../../Api/AppApi";
import lodash from "lodash";
import {Form, Image, notification, Table, Tag, Typography} from "antd";
import {AccessDenied} from "../../../../Component/AccessDenied";
import moment from "moment";
import Paragraph from "antd/lib/typography/Paragraph";
import IconDrawFailedImg from "../../../../resources/images/Drawer-Fail.png";
import {FilterForm} from "./FilterForm";

export const MailRequest = () => {

    const { appCode } = useParams()
    const [notAllowedError, setNotAllowedError] = useState<boolean | null>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [app, setApp] = useState<AppDomain>(AppDefault)
    const location = useLocation()
    const { xs } = useBreakpoint()
    const [form] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const [mailRequests, setMailRequests] = useState([])
    const [pageSize, setPageSize] = useState(Number(searchParams.get("pageSize")) || 25)
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
    const [total, setTotal] = useState(0)

    const [columns] = useState([
        {
            title: "Mã template",
            dataIndex: "template",
            key: "template",
            width: '30%',
            render: (value: any) => value ? <span className={'white-space-pre-wrap'}>{value}</span> : '--'
        },
        {
            title: <span className={'white-space-pre-wrap'}>Thời gian request</span>,
            dataIndex: "requestTime",
            key: "requestTime",
            width: '40%',
            render: (timestamp: any) => {
                const timestampFormatted = moment(timestamp).format("HH:mm:ss DD/MM/YYYY")
                const toolTipElement = <p
                    className={`'truncate-multiline-1`}>{timestamp ? timestampFormatted : '"Thời gian request"'}</p>
                return (
                    <Paragraph ellipsis={{tooltip: toolTipElement}} className="mg-bt-0 white-space-pre-wrap">
                        {timestamp ? timestampFormatted : "--"}
                    </Paragraph>
                )
            },
        },
        {
            title: <span className={'break-word'}>Tổng số</span>,
            dataIndex: "emailQuantity",
            key: "emailQuantity",
            width: '15%',
        },
        {
            title: "",
            key: "detail",
            width: '20%',
            render: (row: any) => {
                return <Link to={`/apps/${appCode}/mail-requests/${row.id}/mail-sends`} className={'pointer'}>Chi tiết</Link>
            }
        }
    ])

    useEffect(() => {
        const fetchAppDetail = async () => {
            setIsLoading(true)
            try {
                const response = await AppApi.getAppByCode(appCode!)
                setApp(response.data)
                setIsLoading(false)
            } catch (err: any) {
                const { status } = err.response
                if (status === 403) {
                    setNotAllowedError(true)
                }
                if(lodash.get(err.response, 'status') !== 401){
                    notification.error({ message: lodash.get(err.response, 'title') || "Đã có lỗi xảy ra !" })
                }
                setIsLoading(false)
            }
        }
        fetchAppDetail()

        return () => {
            setNotAllowedError(null)
        }
    }, [appCode, location])

    useEffect(() => {
        const template = searchParams.get('template') || ""
        const requestTimeFrom = searchParams.get("requestTimeFrom") || ""
        const requestTimeTo = searchParams.get("requestTimeTo") || ""
        const offset = searchParams.get("offset") || 0
        const limit = pageSize
        const sort = searchParams.get("sort") || "createdAt:desc"

        const filter = {offset, limit, sort, template, requestTimeFrom, requestTimeTo}

        const fetchMailSend = async () => {
            try {
                setIsLoading(true)
                const response = await AppApi.filterMailRequest(appCode!, filter)
                const {data} = response
                const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
                const total = parseInt(lodash.get(response, "headers.x-total-count"))
                const pageSize = parseInt(lodash.get(response, "headers.x-page-size"))
                setPageSize(pageSize)
                setMailRequests(data)
                setCurrentPage(currenPage + 1)
                setPageSize(pageSize)
                setTotal(total)
                setIsLoading(false)
            } catch (err: any) {
                setIsLoading(false)
            }
        }
        fetchMailSend()
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [appCode, searchParams, form, pageSize])

    const handleChangePage = (page: number) => {
        const params: { [key: string]: any } = {
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize,
            name: searchParams.get("name") || "",
            subject: searchParams.get("subject") || "",
        }
        const keyOfItemEmpty = Object.keys(params).filter(
            (key) => lodash.isEmpty(params[key]) && !lodash.includes(["page", "limit", "offset"], key)
        )
        setSearchParams({
            ...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
        })
    }

    const onShowSizeChange = (current: any, pageSize: any) => {
        setPageSize(pageSize)
    }

    const localeCustom = {
        emptyText: (
            <>
                {!isLoading && (
                    <div className="mg-bt-8">
                        <div>
                            <Image preview={false} src={IconDrawFailedImg}/>
                        </div>
                        <div>
                            <span>Không có dữ liệu.</span>
                        </div>
                    </div>
                )}
            </>
        ),
    }

    if (notAllowedError) {
        return <AccessDenied />
    }

    return (
        <div className="app__detail">
            <DefaultLayout
                title={`${!xs ? (app.name && app.name.length > 30 ? `${app.name.substring(0, 16)}` : app.name) + " - " : ""}Chi tiết ứng dụng - M29`}
            >
                <div className="main-content template">
                    <AppMenu appCode={appCode} currentKey={'MAIL-REQUEST'} />

                    <div className="template-box overflow">
                        <div className="template-box-table">
                            <div className="template-box-table-head">
                                <Typography.Title level={5}>
                                    Danh Sách Mail Đã Gửi
                                    <Tag color="#E2FBFA">{total}</Tag>
                                </Typography.Title>
                                <div className="line-divider"/>
                            </div>
                            <FilterForm appCode={appCode!}/>
                            <div className="mail-send">
                                <Table
                                    className={'table-responsive'}
                                    rowKey={(record: any) => record.id}
                                    columns={columns}
                                    dataSource={mailRequests}
                                    loading={isLoading}
                                    locale={localeCustom}
                                    bordered
                                    pagination={{
                                        current: currentPage,
                                        total: total,
                                        pageSize: pageSize,
                                        hideOnSinglePage: true,
                                        onChange: handleChangePage,
                                        onShowSizeChange: onShowSizeChange,
                                    }}
                                    scroll={{y:600}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        </div>
    )
}
