import { LinkOutlined } from "@ant-design/icons"
import { Badge, Button, Col, Image, Row, Table } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import Paragraph from "antd/lib/typography/Paragraph"
import { useState } from "react"
import { Link } from "react-router-dom"
import WhiteBox from "../../Component/Custom/WhiteBox"
import { AppDomain } from "../../Domain/AppDomain"
import { APP_STATUS } from "../../Util/Constants"
import IconDrawFailedImg from "./../../resources/images/Drawer-Fail.png"

export const getColor = (status: string) => {
	let statusRender
	for (const stt in APP_STATUS) {
		if (stt === status) {
			const { name, color } = APP_STATUS[status]
			statusRender = <Badge color={color} text={name} />
		}
	}
	return statusRender
}

interface AppsProps {
	isLoading?: boolean
	data?: Array<AppDomain>
	total: number
	pageSize: number
	currentPage: number
	showModalAppCreate?: () => void
	onChangePage: (page: number, pageSize: number) => void
}

const List: React.FC<AppsProps> = (props) => {
	const { isLoading, total, pageSize, data, currentPage } = props
	const [ellipsis] = useState(true)
	const { md } = useBreakpoint()
	const [columns] = useState([
		{
			title: "Tên",
			dataIndex: "name",
			key: "name",
			width: "152px",
			render: (name: string, record: any) => (
				<Link to={`${record.code}`} className="fw-500-impt data-link">
					<Paragraph className={`mg-bt-0 data-link`} ellipsis={ellipsis ? {tooltip: <p className="truncate-multiline w-400">{name}</p>} : false}>{name}</Paragraph>
				</Link>
			),
		},
		{
			title: "Mô Tả",
			dataIndex: "description",
			width: "386px",
			key: "des",
			render: (desc: any) => (
				<Paragraph className="mg-bt-0" ellipsis = {ellipsis ? { tooltip: <p className="truncate-multiline w-500">{desc}</p>, rows: 1 } : false }>{desc}</Paragraph>
			)
			,
		},
		{
			title: "Chủ Sở Hữu",
			dataIndex: "owner",
			width: "186px",
			key: "owner",
		},
		{
			title: "Trạng Thái",
			key: "status",
			width: "186px",
			dataIndex: "status",
			render: (status: any) => getColor(status),
		},
		{
			title: "Sản Lượng",
			dataIndex: "qty",
			width: "186px",
			key: "qty",
		},
	])


	const localeCustom = {
		emptyText: (
			<>
				{!props.isLoading && (
					<div className="empty">
						<Row justify="center" className="mg-bt-8">
							<Col lg={24}>
								<Image preview={false} src={IconDrawFailedImg} />
							</Col>
							<Col lg={24} className="text-disabled">
								<span>Chưa có ứng dụng. Tạo ứng dụng mới!</span>
							</Col>
						</Row>
						<Row justify="center">
							<Button
								className="btn active"
								icon={<LinkOutlined />}
								onClick={() => props.showModalAppCreate!()}
							>
								Tạo ứng dụng
							</Button>
						</Row>
					</div>
				)}
			</>
		),
	}

	const handleChangePage = (page: number, pageSize: number) => {
		props.onChangePage(page, pageSize)
	}

	const showTotal = (total: number) => {
		return ` ${total} bản ghi`
	}

	return (
		<WhiteBox className={`app-list ${md ? "pd-24" : ""}`}>
			<Table
				rowKey={(record: AppDomain) => record.id}
				columns={columns}
				dataSource={data}
				locale={localeCustom}
				loading={isLoading}
				scroll={{ y: 500 }}
				pagination={{
					current: currentPage,
					total: total,
					pageSize: pageSize,
					hideOnSinglePage: true,
					onChange: handleChangePage,
					showTotal: showTotal,
				}}
			/>
		</WhiteBox>
	)
}

export default List
