import {
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from "@ant-design/icons"
import { Button, Form, Input, Modal, notification, Upload } from "antd"
import { RcFile } from "antd/lib/upload"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AppApi } from "../../Api/AppApi"
import { AppDomain } from "../../Domain/AppDomain"

const { Item } = Form

export interface Props {
	onVisibleChange: (visible: boolean) => void
	onSuccess: () => void
	app: AppDomain
}

const ModalAppEdit: React.FC<Props> = (props: Props) => {
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const { appCode } = useParams()

	const [isLoadingUpload, setIsLoadingUpload] = useState(false)
	const [imageUrl, setImageUrl] = useState<string | null>()

	const [form] = Form.useForm()

	function beforeUpload(file: RcFile) {
		if (!file.type.startsWith("image")) {
			notification.error({
				message: "Chỉ cho phép upload file ảnh",
			})

			return false
		}

		setIsLoadingUpload(true)

		AppApi.uploadAvatar(file)
			.then((response) => {
				const avatar = response.data
				setImageUrl(avatar)
				form.setFieldsValue({ avatar })
				setSubmitButtonDisabled(false)
			})
			.catch((error) => {
				notification.error({
					message: error.message,
				})
			})
			.finally(() => {
				setIsLoadingUpload(false)
			})

		return false
	}

	useEffect(() => {
		form.setFieldsValue({ ...props.app })
		setImageUrl(props.app.avatar)
	}, [appCode, props.app, form])

	const onFinish = async (values: any) => {
		try {
			setIsLoading(true)
			await form.validateFields()
			await AppApi.update(appCode!, {
				...values,
				appCode,
				avatar: form.getFieldValue("avatar"),
			})
			setIsLoading(false)
			props.onSuccess()
			props.onVisibleChange(false)

			notification.success({ message: "Cập nhật thành công !" })
		} catch (err: any) {
			notification.error({ message: err.message || "Đã có lỗi xảy ra !" })
			setIsLoading(false)
		}
	}

	const uploadButton = (
		<div>
			{isLoadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	)

	const onFieldsChangeHandle = () => {
		setSubmitButtonDisabled(form.getFieldsError().some((field) => field.errors.length > 0))
	}
	const handleDeleteAvatar = (e: any) => {
		e.stopPropagation()
		form.setFieldsValue({ ...props.app, avatar: "" })
		onFieldsChangeHandle()
		setImageUrl("")
	}

	const footerCustom = [
		<Button
			key="cancel"
			htmlType="button"
			icon={<CloseOutlined />}
			onClick={() => props.onVisibleChange(false)}
			className="btn cancel"
		>
			Hủy Bỏ
		</Button>,
		<Button
			form="form"
			key="submit"
			htmlType="submit"
			icon={<CheckOutlined />}
			disabled={submitButtonDisabled}
			className={`btn ${submitButtonDisabled ? "hover-dis" : ""}`}
			loading={isLoading}
		>
			Xác nhận
		</Button>,
	]

	return (
		<Modal
			title={"Chỉnh sửa thông tin ứng dụng"}
			visible={true}
			onCancel={() => props.onVisibleChange(false)}
			width={700}
			getContainer={false}
			okText="Xác nhận"
			forceRender
			cancelText="Huỷ bỏ"
			footer={footerCustom}
			className="app-modal"
		>
			<Form
				form={form}
				onFinish={onFinish}
				name="form"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				autoComplete="off"
				initialValues={{ modifier: "public" }}
				onFieldsChange={onFieldsChangeHandle}
			>
				<Item
					label={<span className="need-required">Tên ứng dụng :</span>}
					name="name"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập tên ứng dụng",
						},
						{
							whitespace: true,
							message: "Tên ứng dụng không hợp lệ",
						},
					]}
				>
					<Input autoFocus={true} className="py-5 px-12" placeholder="Nhập tên ứng dụng" />
				</Item>

				<Item label={<span>Ảnh :</span>} className="mg-bt-0 upload-action">
					<Upload
						accept={"image/*"}
						listType="picture-card"
						className="avatar-uploader "
						showUploadList={false}
						beforeUpload={beforeUpload}
					>
						{isLoadingUpload ? (
							<LoadingOutlined />
						) : imageUrl ? (
							<>
								<div className="info">
									<img src={imageUrl} alt="avatar" className="pd-8 image-auto" />
								</div>
								<div className="upload-action__btn">
									<FormOutlined className="mg-r-12" />
									<DeleteOutlined onClick={(e) => handleDeleteAvatar(e)} />
								</div>
							</>
						) : (
							uploadButton
						)}
					</Upload>
				</Item>

				<Item label={<span>Mô tả :</span>} name="description">
					<Input.TextArea className="py-5 px-12" placeholder="Nhập mô tả..." />
				</Item>
			</Form>
		</Modal>
	)
}

export default ModalAppEdit
