import {FileFilled, HomeFilled, MailFilled} from "@ant-design/icons"
import React from "react"
import {Link} from "react-router-dom"

interface Menu {
	icon: JSX.Element
	title: string
	key: string
	url: string
}

interface AppMenuProps {
	appCode?: string
	currentKey: string
}

const AppMenu: React.FC<AppMenuProps> = (props) => {
	const { currentKey } = props

	const listMenu: Menu[] = [
		{
			icon: <HomeFilled />,
			title: "Tổng quan",
			key: "OVER_VIEW",
			url: `/apps/${props.appCode}`,
		},
		{
			icon: <FileFilled />,
			title: "Template",
			key: "TEMPLATES",
			url: `/apps/${props.appCode}/templates`,
		},
		{
			icon: <MailFilled />,
			title: "Mail đã gửi",
			key: "MAIL-REQUEST",
			url: `/apps/${props.appCode}/mail-requests`,
		},
	]

	return (
		<>
			<div className="template-wrapper-tabs">
				<div className="template-box template-tabs">
					{listMenu.map((item) => (
						<Link to={item.url} className={`tab-item ${item.key === currentKey ? "active" : ""}`} key={item.key}>
							<div className="tab-item-icon">{item.icon}</div>
							<div className="tab-item-text">{item.title}</div>
						</Link>
					))}
				</div>
			</div>
		</>
	)
}

export default AppMenu
