import React, {useEffect} from 'react';
import DefaultLayout from "../../Component/Layout/Default";
import {useNavigate} from "react-router-dom";

export const Home = (props: any) => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/apps')
    }, [navigate])

    return (
        <DefaultLayout
            {...props}
            loading={false}
            title={"Bảng điều khiển"}
        >
            <div className="main-content">
                <div className="white-box white-box--padding-15px">
                    <div>Bảng điều khiển</div>
                </div>
            </div>
        </DefaultLayout>
    );
}
