import React, { useState } from "react"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, notification, Row } from "antd"
import { AppApi } from "../../Api/AppApi"
import { useParams } from "react-router-dom"
import lodash from "lodash"
const { Item } = Form

export interface Props {
	onVisibleChange: (visible: boolean) => void
	onSuccess: () => void
}

const ModalUserCreate: React.FC<Props> = (props: Props) => {
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

	const [form] = Form.useForm()
	const { appCode } = useParams()

	const footerCustom = [
		<Button
			className="btn cancel"
			key="cancel"
			htmlType="button"
			icon={<CloseOutlined />}
			onClick={() => props.onVisibleChange(false)}
		>
			Hủy Bỏ
		</Button>,
		<Button
			key="ok"
			form="form_edit"
			className="btn"
			htmlType="submit"
			disabled={submitButtonDisabled}
			icon={<CheckOutlined />}
		>
			Xác nhận
		</Button>,
	]

	const handleFinish = () => {
		form.validateFields()
			.then((values) => {
				return AppApi.addMember(appCode!, values)
			})
			.then(() => {
				props.onSuccess()
				props.onVisibleChange(false)
				notification.success({
					message: "Thêm User thành công",
				})
			})
			.catch((err) => {
				if (lodash.get(err, "response.status") === 400) {
					const { title } = err.response.data
					if (title === "member_exists") {
						notification.error({ message: "Thành viên đã tồn tại!" })
					}
					if (title === "owner_not_allowed") {
						notification.error({ message: "Chủ sở hữu không được thêm làm thành viên!" })
					}
				} else {
					notification.error({ message: err.message || "Đã xảy ra lỗi !" })
				}
			})
	}

	const onFieldsChangeHandle = () => {
		setSubmitButtonDisabled(form.getFieldsError().some((field) => field.errors.length > 0))
	}

	return (
		<Modal
			title="Thêm Nhân Viên"
			visible={true}
			centered
			onCancel={() => props.onVisibleChange(false)}
			width={700}
			okText="Xác nhận"
			cancelText="Huỷ bỏ"
			closable={true}
			footer={footerCustom}
			className="app-modal "
		>
			<Row align="middle" gutter={[16, 24]} className="app-modal-edit">
				<Col xs={24}>
					<Form form={form} onFinish={handleFinish} name="form_edit" onFieldsChange={onFieldsChangeHandle}>
						<Item
							label={<span className="need-required mg-r-22">Nhập E-mail:</span>}
							name="email"
							rules={[
								{
									required: true,
									message: "Vui lòng nhập email...",
								},
								{
									type: "regexp",
									pattern: new RegExp(/^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/),
									message: "Email không hợp lệ...",
								},
								{
									type: "email",
									message: "Email không hợp lệ...",
								},
							]}
						>
							<Input autoFocus={true} placeholder="E-mail..." />
						</Item>
					</Form>
				</Col>
			</Row>
		</Modal>
	)
}

export default ModalUserCreate
