import React from "react";
import {TenantDomain, TenantModel} from "../Domain/TenantDomain";

export interface AppContextValueInterface {
    state: {
        tenant: TenantDomain
    },
    func: {}
}

export const defaultAppContextValue: AppContextValueInterface = {
    state: {
        tenant: TenantModel
    },
    func: {}
}

const AppContext = React.createContext<AppContextValueInterface>({...defaultAppContextValue});

export {AppContext};
