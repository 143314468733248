import {notification} from "antd"
import lodash from "lodash"
import React, {useEffect, useState} from "react"
import {useSearchParams} from "react-router-dom"
import DefaultLayout from "../../../Component/Layout/Default"
import AppCriteria from "../../../Criteria/AppCriteria"
import {AppDomain} from "../../../Domain/AppDomain"
import DataList from "./DataList"
import {BlackListApi} from "../../../Api/BlackListApi";

const BlackList = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [appList, setAppList] = useState<Array<AppDomain>>([])
	const [isLoading, setIsLoading] = useState(false)

	const [pageSize, setPageSize] = useState(Number(searchParams.get("limit")) || 25)
	const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const name = searchParams.get("name") || ""
		const offset = searchParams.get("offset") || 0
		const limit = pageSize
		const sort = searchParams.get("sort") || "createdAt:desc"
		const status = searchParams.get("status") || ""
		const isOwner = true

		const filter: AppCriteria = { name, offset, limit, sort, status, isOwner }

		const fetchBlackList =  () => {
			setIsLoading(true)
			BlackListApi.filter(filter)
				.then(response => {
					const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
					const total = parseInt(lodash.get(response, "headers.x-total-count"))
					const pageSize = parseInt(lodash.get(response, "headers.x-page-size"))

					setPageSize(pageSize)
					setAppList(response.data)
					setCurrentPage(currenPage + 1)
					setTotal(total)
					setIsLoading(false)
				})
				.catch((err) => {
					notification.error({ message: err.message || "Đã có lỗi xảy ra !" })
					setIsLoading(false)
				})
		}

		fetchBlackList()
	}, [searchParams, setCurrentPage, pageSize])

	const handleChangePage = (page: number, pageSize: number) => {
		setCurrentPage(page)
		setPageSize(pageSize)

		const params: { [key: string]: any } = {
			page: page,
			limit: pageSize,
			offset: (page - 1) * pageSize,
			name: searchParams.get("name") || "",
			status: searchParams.get("status") || "",
		}

		const keyOfItemEmpty = Object.keys(params).filter(
			(key) => lodash.isEmpty(params[key]) && !lodash.includes(["page", "limit", "offset"], key)
		)
		setSearchParams({
			...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
		})
	}

	const handleReopenMail = (email: string) => {
		setIsLoading(true)
		BlackListApi.open(email)
			.then(() => {
				notification.success({
					message: "Mở lại email thành công"
				})
				setSearchParams({t: (new Date()).getTime().toString()})
			})
			.catch((error) => {
				notification.error({
					message: "Có lỗi: " + error.message
				})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<>
			<DefaultLayout title={"Danh sách blacklist - M29"}>
				<DataList
					data={appList}
					total={total}
					pageSize={pageSize}
					isLoading={isLoading}
					currentPage={currentPage}
					onChangePage={handleChangePage}
					onReopenMail={handleReopenMail}
				/>
			</DefaultLayout>
		</>
	)
}

export default BlackList
