import {LinkOutlined, PlusOutlined, RedoOutlined, SearchOutlined} from "@ant-design/icons"
import {Button, Form, Image, Input, Modal, notification, Row, Table, Tag, Typography} from "antd"
import {ColumnsType} from "antd/es/table"
import lodash from "lodash"
import React, {useEffect, useState} from "react"
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom"
import {MailTemplateApi} from "../../../../Api/MailTemplateApi"
import MailTemplateCriteria from "../../../../Criteria/MailTemplateCriteria"
import MailTemplateDomain from "../../../../Domain/MailTemplateDomain"
import {getCleanFormFilter} from "../../../../Util/Utils"
import IconDrawFailedImg from "./../../../../resources/images/Drawer-Fail.png"
import DefaultLayout from "../../../../Component/Layout/Default";
import AppMenu from "../AppMenu";
import {AppApi} from "../../../../Api/AppApi";
import {AppDefault, AppDomain} from "../../../../Domain/AppDomain";
import {AccessDenied} from "../../../../Component/AccessDenied";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface TemplateListProps {

}

const MailTemplate: React.FC<TemplateListProps> = () => {
    const {appCode} = useParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [templateList, setTemplateList] = useState<Array<MailTemplateDomain>>([])
    const [pageSize, setPageSize] = useState(Number(searchParams.get("pageSize")) || 25)
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
    const [total, setTotal] = useState(0)
    const [app, setApp] = useState<AppDomain>(AppDefault)
    const [notAllowedError, setNotAllowedError] = useState<boolean | null>()
    const {xs} = useBreakpoint()

    const columns: ColumnsType<MailTemplateDomain> = [
        {
            title: "Mã",
            dataIndex: "code",
            key: "code",
            width: "272px",
        },
        {
            title: "Tên",
            dataIndex: "name",
            key: "name",
            width: "480px",
        },
        {
            title: "Tác vụ",
            key: "action",
            width: "112px",
            align: "right" as "right",
            render: (record) => (
                <div className="d-plex justify-end">
                    <div className="type-link mg-r-10"
                         onClick={() => navigate(`/apps/${appCode}/templates/${record.code}/edit`)}>
                        Sửa
                    </div>
                    <div className="type-link" onClick={() => handleRemoveTemplate(record.code)}>
                        Xoá
                    </div>
                </div>
            ),
        },
    ]

    useEffect(() => {
        const fetchAppDetail = async () => {
            setIsLoading(true)
            try {
                const response = await AppApi.getAppByCode(appCode!)
                setApp(response.data)
                setIsLoading(false)
            } catch (err: any) {
                const {status} = err.response
                if (status === 403) {
                    setNotAllowedError(true)
                }
                if (lodash.get(err.response, 'status') !== 401) {
                    notification.error({message: lodash.get(err.response, 'title') || "Đã có lỗi xảy ra !"})
                }
                setIsLoading(false)
            }
        }

        fetchAppDetail()
    }, [appCode])

    useEffect(() => {
        if (searchParams.get("name")) form.setFieldsValue({name: searchParams.get("name")})
        if (searchParams.get("code")) form.setFieldsValue({status: searchParams.get("code")})
        const filter: MailTemplateCriteria = {
            sort: searchParams.get("sort") || "createdAt:desc",
            code: searchParams.get("code") || "",
            name: searchParams.get("name") || "",
            offset: Number(searchParams.get("offset")) || 0,
            limit: pageSize,
        }

        const fetchMailTemplate = async () => {
            try {
                setIsLoading(true)
                const response: any = await MailTemplateApi.filter(appCode!, filter)
                const {data} = response
                const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
                const total = parseInt(lodash.get(response, "headers.x-total-count"))

                setTemplateList(data)
                setCurrentPage(currenPage + 1)
                setPageSize(parseInt(lodash.get(response, "headers.x-page-size")))
                setTotal(total)
                setIsLoading(false)
            } catch (err: any) {
                setIsLoading(false)
            }
        }

        fetchMailTemplate()
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [appCode, searchParams, form, pageSize])

    const onFinish = () => {
        setSearchParams(getCleanFormFilter(form.getFieldsValue(true)))
    }

    const handleChangePage = (page: number) => {
        const params: { [key: string]: any } = {
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize,
            name: searchParams.get("name") || "",
            code: searchParams.get("code") || "",
        }
        const keyOfItemEmpty = Object.keys(params).filter(
            (key) => lodash.isEmpty(params[key]) && !lodash.includes(["page", "limit", "offset"], key)
        )
        setSearchParams({
            ...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
        })
    }

    const handleRemoveTemplate = (templateId: any) => {
        Modal.confirm({
            title: "Bạn có chắc chắn muốn xoá template này?",
            onOk: () => {
                MailTemplateApi.removeTemplate(appCode!, templateId)
                    .then(() => {
                        notification.success({
                            message: "Xoá template thành công",
                        })

                        setSearchParams({t: new Date().getTime().toString()})
                    })
                    .catch(() => {
                        notification.error({
                            message: "Xoá template thất bại",
                        })
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            },
            okButtonProps: {
                loading: isLoading,
                disabled: isLoading,
            },
            cancelButtonProps: {
                loading: isLoading,
                disabled: isLoading,
            },
        })
    }

    const localeCustom = {
        emptyText: (
            <>
                {!isLoading && (
                    <>
                        <div className="mg-bt-8">
                            <div>
                                <Image preview={false} src={IconDrawFailedImg}/>
                            </div>
                            <div>
                                <span>Chưa có template.</span> <span> Tạo mới để thiết lập!</span>
                            </div>
                        </div>
                        <div>
                            <Button
                                className="btn active"
                                icon={<LinkOutlined/>}
                                onClick={() => navigate(`/apps/${appCode}/templates/add`)}
                            >
                                Thêm mới template
                            </Button>
                        </div>
                    </>
                )}
            </>
        ),
    }

    const clearFilter = () => {
        form.resetFields()
        setSearchParams({})
    }

    const onShowSizeChange = (current: any, pageSize: any) => {
        setPageSize(pageSize)
    }

    if (notAllowedError) {
        return <AccessDenied/>
    }

    return (
        <div className="app__detail">
            <DefaultLayout
                title={`${!xs ? (app.name && app.name.length > 30 ? `${app.name.substring(0, 16)}` : app.name) + " - " : ""}Chi tiết ứng dụng - M29`}
            >
                <div className="main-content template">
                    <AppMenu appCode={appCode} currentKey={'TEMPLATES'}/>
                    <div className="template-box">
                        <div className="template-box-table">
                            <div className="template-box-table-head">
                                <Typography.Title level={5}>
                                    Danh Sách Template
                                    <Tag color="#E2FBFA">{total}</Tag>
                                </Typography.Title>
                                <div className="line-divider"/>
                                <Link to={`/apps/${appCode}/templates/add`}>
                                    <Button className="btn active">
                                        <PlusOutlined/>
                                        Thêm Template
                                    </Button>
                                </Link>
                            </div>

                            <Form
                                form={form}
                                name="form_filter_template"
                                layout="inline"
                                onFinish={onFinish}
                                className="mg-bt-16 template-temp d-plex justify-between items-center"
                                initialValues={{name: "", code: ""}}
                                colon={true}
                            >
                                <div className="d-plex template-temp-input">
                                    <Form.Item label="Mã Template:" name="code">
                                        <Input placeholder="Tìm theo mã..." suffix={<SearchOutlined/>}/>
                                    </Form.Item>
                                    <Form.Item label="Tên Template:" name="name">
                                        <Input placeholder="Tìm theo tên..." suffix={<SearchOutlined/>}/>
                                    </Form.Item>
                                </div>
                                <Form.Item className="template-temp-input-clear pd-t-10">
									<span className="clear" onClick={clearFilter}>
										<RedoOutlined/> Làm mới bộ lọc
									</span>
                                    <Button className="btn" htmlType="submit" icon={<SearchOutlined/>}>
                                        Tìm Kiếm
                                    </Button>
                                </Form.Item>
                            </Form>

                            <Row className="mg-bt-12">
								<span className="clear" onClick={clearFilter}>
									<RedoOutlined/> Làm mới bộ lọc
								</span>
                            </Row>

                            <div className="template-box-table-list">
                                <Table
                                    rowKey={(record: MailTemplateDomain) => record.id}
                                    columns={columns}
                                    dataSource={templateList}
                                    loading={isLoading}
                                    locale={localeCustom}
                                    pagination={{
                                        current: currentPage,
                                        total: total,
                                        pageSize: pageSize,
                                        hideOnSinglePage: true,
                                        onChange: handleChangePage,
                                        onShowSizeChange: onShowSizeChange,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        </div>
    )
}

export default MailTemplate
