import { CopyOutlined } from "@ant-design/icons"
import { Col, Form, Image, Row } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import React from "react"
import { AppDomain } from "../../Domain/AppDomain"
import WarnIcon from "../../resources/images/warn-icon.png"

const { Item } = Form

export interface Props {
	app: AppDomain
}

const ModalAppResetSecret: React.FC<Props> = (props: Props) => {
	return (
		<Row align="middle" gutter={[16, 24]}>
			<Col xs={4}>
				<div className="warn-box">
					<Image preview={false} src={WarnIcon} />
				</div>
			</Col>
			<Col xs={20}>
				<b className="font-size-16 line-h-24 ">Chú ý!</b>
				<br />
				Mã bảo mật Secret Key&nbsp;
				<b>rất quan trọng và chỉ cấp một lần</b>. Bạn vui lòng lưu lại Secret Key vào một nơi an toàn!
			</Col>
			<Col xs={24}>
				<Form>
					<Item label={<span className="mg-r-6">Mã Secret Key :</span>}>
						<Paragraph
							className="secret-key"
							copyable={{
								text: props.app.secret,
								tooltips: false,
								icon: [<CopyOutlined key="copy-icon" className="copyable" />],
							}}
						>
							{props.app.secret}
						</Paragraph>
					</Item>
				</Form>
			</Col>
		</Row>
	)
}

export default ModalAppResetSecret
