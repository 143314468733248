import {Button, DatePicker, Form, Select} from "antd";
import moment from "moment";
import {RedoOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import lodash from "lodash";
import MailTemplateDomain from "../../../../Domain/MailTemplateDomain";
import MailTemplateCriteria from "../../../../Criteria/MailTemplateCriteria";
import {MailTemplateApi} from "../../../../Api/MailTemplateApi";

const RangePicker = DatePicker.RangePicker
const beginDateFormat = "YYYY-MM-DD HH:mm:00"
const endDateFormat = "YYYY-MM-DD HH:mm:59"
const {Option} = Select;

type FilterFormProps = {
    appCode: string,
}

export const FilterForm = (props: FilterFormProps) => {
    const [form] = Form.useForm()
    const [searchParams, setSearchParams] = useSearchParams()
    const [pageSize,] = useState(Number(searchParams.get("pageSize")) || 25);
    const [templates, setTemplates] = useState<MailTemplateDomain[]>([]);
    const [templateSearch, setTemplateSearch] = useState('');
    const {appCode} = props;

    const getFormState = () => {
        let requestTimeFrom = form.getFieldValue('requestRangeTime') && form.getFieldValue('requestRangeTime')[0]
            ? moment(form.getFieldValue('requestRangeTime')[0].format(beginDateFormat)).toISOString() : null;
        let requestTimeTo = form.getFieldValue('requestRangeTime') && form.getFieldValue('requestRangeTime')[1]
            ? moment(form.getFieldValue('requestRangeTime')[1].format(beginDateFormat)).toISOString() : null;
        return {...form.getFieldsValue(true), requestTimeFrom, requestTimeTo}
    }

    const clearFilter = () => {
        form.setFieldsValue({
            template:null,
            requestRangeTime:[]
        })
        setSearchParams({})
    }

    const handleFilter = () => {
        const params = {
            ...Object.fromEntries(searchParams.entries()),
            ...getFormState()
        }

        setSearchParams({
            ...lodash.omitBy(params, v => !v),
            limit: pageSize.toString(),
            offset: "0"
        })
    }

    const handleChangeRangePicker = (field: string, values: any) => {
        let requestTimeFrom = searchParams.get("requestTimeFrom") || ""
        let requestTimeTo = searchParams.get("requestTimeTo") || ""

        if (field === "requestTime") {
            requestTimeFrom = values && values[0] ? moment(values[0].format(beginDateFormat)).toISOString() : ""
            requestTimeTo = values && values[1] ? moment(values[1].format(endDateFormat)).toISOString() : ""
        }
        const params = Object.fromEntries(searchParams.entries())
        params.requestTimeFrom = requestTimeFrom
        params.requestTimeTo = requestTimeTo

        setSearchParams({
            ...lodash.omitBy(params, v => !v),
            limit: pageSize.toString(),
            offset: "0"
        })
    }

    const handleChangeTemplate = (value: any) => {
        const params = Object.fromEntries(searchParams.entries());
        params.template = value;
        setSearchParams({
            ...lodash.omitBy(params, v => !v),
            limit: pageSize.toString(),
            offset: "0"
        })
    }

    useEffect(() => {
        let offset = 0;
        let filter: MailTemplateCriteria = {offset, limit: 25}
        if (templateSearch) filter.query = templateSearch
        MailTemplateApi.getMailTemplatesAutocomplete(appCode!, filter).then((response) => {
            setTemplates(response.data);
        });
    }, [appCode, templateSearch,searchParams]);

    useEffect(() => {
        form.setFieldsValue({
            template: searchParams.get('template') || undefined,
            requestRangeTime: [
                searchParams.has('requestTimeFrom') ? moment(searchParams.get('requestTimeFrom')) : null,
                searchParams.has('requestTimeTo') ? moment(searchParams.get('requestTimeTo')) : null
            ]
        })
    }, [form, searchParams])

    const handleSearchTemplate = (value: any) => {
        setTemplateSearch(value)
    }

    return (
        <Form
            form={form}
            name="form_filter_mail_send"
            layout="inline"
            onFinish={handleFilter}
            className="mail-send__form mg-bt-20 template-temp d-plex justify-between items-center"
            colon={true}
            labelAlign="left"
            initialValues={{
                template: searchParams.get('template'),
                requestRangeTime: [
                    searchParams.get('requestTimeFrom') ? moment(searchParams.get('requestTimeFrom')) : null,
                    searchParams.get('requestTimeTo') ? moment(searchParams.get('requestTimeTo')) : null,
                ]
            }}
        >
            <div className="d-plex template-temp-input">
                <Form.Item label="Mã template" name="template">
                    <Select
                        placeholder={'Chọn mã template'}
                        className={'width-100pc'}
                        onChange={(value: any) => {
                            handleChangeTemplate(value)
                        }}
                        filterOption={false}
                        showSearch
                        onSearch={lodash.debounce(handleSearchTemplate, 500)}>
                        {templates.map((template: MailTemplateDomain) => {
                            return <Option value={template.code} key={template.code}
                                           className={'width-100pc'}>
                                {template.code}
                            </Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={"Thời gian request"} name={'requestRangeTime'}>
                    <RangePicker
                        className={"width-100pc"}
                        placeholder={["Từ", "Đến"]}
                        format={"DD/MM/YYYY HH:mm"}
                        showTime={true}
                        onChange={(value) => handleChangeRangePicker("requestTime", value)}
                    />
                </Form.Item>
            </div>

            <div className="user-actions">
                <Form.Item className="template-temp-input-clear user-actions__clear">
                    <span onClick={clearFilter}>
                        <RedoOutlined/> Làm mới bộ lọc
                    </span>
                </Form.Item>
                <Form.Item className="template-temp-input-search user-actions__search ">
                    <Button className="btn" htmlType="submit" icon={<SearchOutlined/>}>
                        Tìm Kiếm
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
}
