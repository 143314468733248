import apiClient from "../Util/ApiClient"
import BlackListCriteria from "../Criteria/BlackListCriteria";

export class BlackListApi {
	static filter(criteria: BlackListCriteria) {
		return apiClient.get("/blacklists", {
			params: criteria,
		})
	}

	static open(email: string) {
		return apiClient.patch(`/blacklists/${email}/open`)
	}
}
