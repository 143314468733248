import lodash from "lodash"

export const getCleanFormFilter = (obj: any, keysNoCheck?: string[]) => {
	let keyOfEmptyString = []
	if (keysNoCheck) {
		keyOfEmptyString = Object.keys(obj).filter(
			(key) => lodash.isEmpty(obj[key]) && !lodash.includes(keysNoCheck, key)
		)
	} else {
		keyOfEmptyString = Object.keys(obj).filter((key) => lodash.isEmpty(obj[key]))
	}
	return lodash.omit(obj, keyOfEmptyString)
}
