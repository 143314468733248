import { SettingOutlined } from "@ant-design/icons"
import { Button, Col, Divider, Image, Row, Spin, Typography } from "antd"
import lodash from "lodash"
import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import ModalAppEdit from "../../../Component/Modal/ModalAppEdit"
import { ModalConfirmResetSecret } from "../../../Component/Modal/ModalConfirmResetSecret"
import { AppDomain } from "../../../Domain/AppDomain"
import { getColor } from "../List"

const fallbackAvatar = () => {
	return "https://www.gravatar.com/avatar/" + lodash.random(1111111111, 9999999999) * 10000000000 + "?d=identicon"
}
interface AppInfoProps {
	app: AppDomain
	loading: boolean
	handleAllowed: (val: boolean) => void
}

export const AppInfo: React.FC<AppInfoProps> = ({ app, loading, handleAllowed }) => {
	const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
	const [isShowModalEditAppVisible, setIsShowModalEditAppVisible] = useState(false)
	const [, setSearchParams] = useSearchParams()
	const [ellipsis, setEllipsis] = useState(true)

	const handleModalConfirmVisibleChange = (visible: boolean) => {
		setIsShowModalConfirm(visible)
	}

	const handleAppEditSuccess = () => {
		setSearchParams({ t: Math.floor(Math.random() * 1000).toString() })
	}

	const handleModalEditAppVisibleChange = (visible: boolean) => {
		setIsShowModalEditAppVisible(visible)
	}

	return (
		<>
			{isShowModalEditAppVisible && (
				<ModalAppEdit
					onVisibleChange={handleModalEditAppVisibleChange}
					onSuccess={handleAppEditSuccess}
					app={app}
				/>
			)}

			<div className="app-info mg-bt-12">
				<Spin spinning={loading}>
					<div className="template-box-head">
						<div className="template-box-head-img">
							<Image
								src={app.avatar || "error"}
								preview={{
									mask: "",
									visible: false,
								}}
								alt={app.name}
								fallback={fallbackAvatar()}
							/>
						</div>
						<div style={{ flexGrow: 8 }} className="template-box-head__content flex justify-between">
							<div>
								<div className="template-box-head-title flex items-center justify-between">
									<Typography.Text className={"app-info__name-typo"}>{app?.name}</Typography.Text>
								</div>
								{app?.description && (
									<div className="template-box-head-desc mg-t-5 mg-b-5">
										<Typography.Paragraph
											className={"app-info__desc-typo"}
											ellipsis={ellipsis ? { rows: 2 } : false}
										>
											{app?.description}
										</Typography.Paragraph>
										{app.description.length > 100 ? (
											<span className="type-link" onClick={() => setEllipsis(!ellipsis)}>
												{ellipsis ? "Mở rộng" : "Thu gọn"}
											</span>
										) : null}
									</div>
								)}
							</div>
							<Button className="btn " onClick={() => handleModalEditAppVisibleChange(true)}>
								<SettingOutlined /> Chỉnh Sửa
							</Button>
						</div>
					</div>

					<Divider />
					<div className="template-box-info">
						<Row gutter={20}>
							<Col xs={{ span: 24 }} sm={{ span: 14 }} lg={{ span: 13 }}>
								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>App Secret Key:</h3>
									</Col>
									<Col span={10}>
										<span>{app?.maskedSecret}</span>
									</Col>
									<Col span={6}>
										<Button type="link" onClick={() => handleModalConfirmVisibleChange(true)}>
											Reset
										</Button>
									</Col>
								</div>

								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>Sản lượng:</h3>
									</Col>
									<Col span={10}>
										<span>1.200 / 2.000</span>
									</Col>
									<Col span={6}>
										<Button type="link">Nâng cấp</Button>
									</Col>
								</div>
								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>Chủ sở hữu:</h3>
									</Col>
									<Col span={10}>
										<span>{app?.owner}</span>
									</Col>
									<Col span={6}>
										<Button type="link">Thay đổi</Button>
									</Col>
								</div>
								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>Trạng thái:</h3>
									</Col>
									<Col span={16}>
										<span>{getColor(app?.status)}</span>
									</Col>
								</div>
							</Col>
						</Row>
					</div>
				</Spin>

				{isShowModalConfirm && (
					<ModalConfirmResetSecret
						onVisibleChange={handleModalConfirmVisibleChange}
						onSuccess={handleAppEditSuccess}
						handleAllowed={handleAllowed}
					/>
				)}
			</div>
		</>
	)
}
