import lodash from "lodash"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { AppApi } from "../../Api/AppApi"
import DefaultLayout from "../../Component/Layout/Default"
import ModalCreateApp from "../../Component/Modal/ModalAppCreate"
import ModalAppSecret from "../../Component/Modal/ModalAppSecret"
import AppCriteria from "../../Criteria/AppCriteria"
import { AppDefault, AppDomain } from "../../Domain/AppDomain"
import Filter from "./Filter"
import Heading from "./Heading"
import List from "./List"

const App = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [appList, setAppList] = useState<Array<AppDomain>>([])
	const [isLoading, setIsLoading] = useState(false)

	const [isShowModalCreateVisible, setIsShowModalCreateVisible] = useState(false)
	const [isShowModalSecretVisible, setIsShowModalSecretVisible] = useState(false)

	const [lastCreatedApp, setLastCreatedApp] = useState<AppDomain>(AppDefault)
	const [pageSize, setPageSize] = useState(Number(searchParams.get("limit")) || 25)
	const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const name = searchParams.get("name") || ""
		const offset = searchParams.get("offset") || 0
		const limit = pageSize
		const sort = searchParams.get("sort") || "createdAt:desc"
		const status = searchParams.get("status") || ""
		const isOwnerFromSearch = searchParams.get("isOwner")

		let isOwner
		if (!isOwnerFromSearch || isOwnerFromSearch === "all") {
			isOwner = null
		} else if (isOwnerFromSearch === "owner") {
			isOwner = true
		} else {
			isOwner = false
		}

		const filter: AppCriteria = { name, offset, limit, sort, status, isOwner }

		const fetchApps = async () => {
			try {
				setIsLoading(true)

				const response: any = await AppApi.filter(filter)
				const { data } = response
				const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
				const total = parseInt(lodash.get(response, "headers.x-total-count"))
				const pageSize = parseInt(lodash.get(response, "headers.x-page-size"))
				setPageSize(pageSize)

				setAppList(data)
				setCurrentPage(currenPage + 1)
				setTotal(total)
				setIsLoading(false)
			} catch (err: any) {
				setIsLoading(false)
			}
		}

		fetchApps()
	}, [searchParams, setCurrentPage, pageSize])

	const handleModalCreateAppVisibleChange = (visible: boolean) => {
		setIsShowModalCreateVisible(visible)
	}

	const handleModalAppSecretVisibleChange = (visible: boolean) => {
		setIsShowModalSecretVisible(visible)
	}

	const handleCreateAppSuccess = (app: AppDomain) => {
		setLastCreatedApp(app)
		setSearchParams({ success: Math.floor(Math.random() * 1000).toString() })
		handleModalCreateAppVisibleChange(false)
		handleModalAppSecretVisibleChange(true)
	}

	const handleFilter = (params: any) => {
		const keyOfItemEmpty = Object.keys(params).filter((key) => lodash.isEmpty(params[key]))
		setSearchParams({
			...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
			limit: pageSize.toString(),
			offset: "0",
		})
	}

	const handleChangePage = (page: number, pageSize: number) => {
		setCurrentPage(page)
		setPageSize(pageSize)

		const params: { [key: string]: any } = {
			page: page,
			limit: pageSize,
			offset: (page - 1) * pageSize,
			name: searchParams.get("name") || "",
			status: searchParams.get("status") || "",
			isOwner: searchParams.get("isOwner"),
		}

		const keyOfItemEmpty = Object.keys(params).filter(
			(key) => lodash.isEmpty(params[key]) && !lodash.includes(["page", "limit", "offset"], key)
		)
		setSearchParams({
			...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
		})
	}

	return (
		<>
			{isShowModalCreateVisible && (
				<ModalCreateApp
					onVisibleChange={handleModalCreateAppVisibleChange}
					onSuccess={handleCreateAppSuccess}
				/>
			)}

			{isShowModalSecretVisible && (
				<ModalAppSecret onVisibleChange={handleModalAppSecretVisibleChange} app={lastCreatedApp} />
			)}

			<DefaultLayout title={"Danh sách ứng dụng - M29"}>
				<div className="app">
					<Heading />
					<Filter
						showModalAppCreate={() => handleModalCreateAppVisibleChange(true)}
						totalApp={total}
						onFilter={handleFilter}
					/>
					<List
						data={appList}
						total={total}
						pageSize={pageSize}
						isLoading={isLoading}
						showModalAppCreate={() => handleModalCreateAppVisibleChange(true)}
						currentPage={currentPage}
						onChangePage={handleChangePage}
					/>
				</div>
			</DefaultLayout>
		</>
	)
}

export default App
