import React from "react"

import { InfoCircleOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import Text from "antd/lib/typography/Text"
import WhiteBox from "../../Component/Custom/WhiteBox"
import SecurityService from "../../Util/SecurityService"

const Heading: React.FC = (props) => {
	return (
		<WhiteBox className="app-heading py-16 px-24">
			<Row gutter={[0, { xs: 12, md: 0 }]}>
				<Col span={24}>
					<Text className="df-text">Xin chào,&nbsp;</Text>
					<Text className="cl-text">{SecurityService.getUser()?.name}!</Text>
				</Col>

				<Col span={24} className="mg-bt-12">
					<Text type="secondary" className="des-text">
						Chào mừng bạn đến với M29 - Project!
					</Text>
				</Col>
				<Col span={24}>
					<Text className="py-4 info-text">
						<InfoCircleOutlined className="mg-r-6" />
						M29 là công cụ giúp bạn quản lý, xử lý và theo dõi e-mail tự động một cách thuận tiện nhất
					</Text>
				</Col>
			</Row>
		</WhiteBox>
	)
}

export default Heading
