import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Button, Col, Divider, Image, Modal, notification, Row, Spin, Typography } from "antd"
import lodash from "lodash"
import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { AdminAppApi } from "../../../../Api/AdminAppApi"
import ModalAppEdit from "../../../../Component/Modal/ModalAppEdit"
import { ModalConfirmResetSecret } from "../../../../Component/Modal/ModalConfirmResetSecret"
import { AppDomain } from "../../../../Domain/AppDomain"
import { APP_ACTIONS } from "../../../../Util/Constants"
import { getColor } from "../DataList"
const { confirm } = Modal

const fallbackAvatar = () => {
	return "https://www.gravatar.com/avatar/" + lodash.random(1111111111, 9999999999) * 10000000000 + "?d=identicon"
}
interface AppInfoProps {
	app: AppDomain
	loading: boolean
	handleAllowed: (val: boolean) => void
}

export const AppInfo: React.FC<AppInfoProps> = (props) => {
	const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
	const [isShowModalEditAppVisible, setIsShowModalEditAppVisible] = useState(false)
	const [, setSearchParams] = useSearchParams()
	const [ellipsis, setEllipsis] = useState(true)

	const handleModalConfirmVisibleChange = (visible: boolean) => {
		setIsShowModalConfirm(visible)
	}

	const handleAppEditSuccess = () => {
		setSearchParams({ t: Math.floor(Math.random() * 1000).toString() })
	}


	const handleModalEditAppVisibleChange = (visible: boolean) => {
		setIsShowModalEditAppVisible(visible)
	}

	const actionName =
		props.app.status === "INACTIVE" ? APP_ACTIONS.ACTIVE : props.app.status === "ACTIVE" ? APP_ACTIONS.OFF : APP_ACTIONS.ON

	const handleChangeStatus = async (code: string, status: string) => {
		const newStatus = status === "ACTIVE" ? "DISABLED" : "ACTIVE"
		try {
			await AdminAppApi.updateStatus(code, { status: newStatus.toString() })
			setSearchParams({ success: Math.floor(Math.random() * 1000).toString() })
			notification.success({ message: "Cập nhật thành công !" })
		} catch (err: any) {
			notification.error({ message: err.message || "Đã có lỗi xảy ra !" })
		}
	}

	const onConfirmChangeStatus = () => {
		confirm({
			className: "app-modal app-modal-secret",
			title: "Đổi trạng thái ứng dụng",
			content: `Bạn có chắc muốn ${actionName.toLowerCase()} ứng dụng này ?`,
			onOk: () => handleChangeStatus(props.app.code, props.app.status),
			okText: <span>Xác nhận</span>,
			cancelText: <span>Hủy Bỏ</span>,
			okButtonProps: { className: "btn", htmlType: "submit", icon: <CheckOutlined />, type: "default" },
			cancelButtonProps: { className: "btn cancel", type: "default", icon: <CloseOutlined /> },
			centered: true,
			closable: true,
			icon: false,
			width: 500,
		})
	}

	return (
		<>
			{isShowModalEditAppVisible && (
				<ModalAppEdit
					onVisibleChange={handleModalEditAppVisibleChange}
					onSuccess={handleAppEditSuccess}
					app={props.app}
				/>
			)}

			<div className="app-info mg-bt-12">
				<Spin spinning={props.loading}>
					<div className="template-box-head">
						<div className="template-box-head-img">
							<Image
								src={props.app.avatar || "error"}
								preview={{
									mask: "",
									visible: false,
								}}
								alt={props.app.name}
								fallback={fallbackAvatar()}
							/>
						</div>
						<div style={{ flexGrow: 8 }} className="template-box-head__content flex justify-between">
							<div>
								<div className="template-box-head-title flex items-center justify-between">
									<Typography.Text className={"app-info__name-typo"}>{props.app?.name}</Typography.Text>
								</div>
								{props.app?.description && (
									<div className="template-box-head-desc mg-t-5 mg-b-5">
										<Typography.Paragraph
											className={"app-info__desc-typo"}
											ellipsis={ellipsis ? { rows: 2 } : false}
										>
											{props.app?.description}
										</Typography.Paragraph>
										{props.app.description.length > 100 ? (
											<span className="type-link" onClick={() => setEllipsis(!ellipsis)}>
												{ellipsis ? "Mở rộng" : "Thu gọn"}
											</span>
										) : null}
									</div>
								)}
							</div>
						</div>
					</div>

					<Divider />
					<div className="template-box-info">
						<Row gutter={20}>
							<Col xs={{ span: 24 }} sm={{ span: 14 }} lg={{ span: 13 }}>
								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>App Secret Key:</h3>
									</Col>
									<Col span={10}>
										<span>{props.app?.maskedSecret}</span>
									</Col>
									<Col span={6}>
										<Button type="link" onClick={() => handleModalConfirmVisibleChange(true)}>
											Reset
										</Button>
									</Col>
								</div>

								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>Sản lượng:</h3>
									</Col>
									<Col span={10}>
										<span>1.200 / 2.000</span>
									</Col>
									<Col span={6}>
										<Button type="link">Nâng cấp</Button>
									</Col>
								</div>
								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>Chủ sở hữu:</h3>
									</Col>
									<Col span={10}>
										<span>{props.app?.owner}</span>
									</Col>
									<Col span={6}>
										<Button type="link">Thay đổi</Button>
									</Col>
								</div>
								<div className="template-box-info-text">
									<Col span={8} className="pd-l-0">
										<h3>Trạng thái:</h3>
									</Col>
									<Col span={10}>
										<span>{getColor(props.app?.status)}</span>
									</Col>
									<Col span={6}>
										<Button type="link" onClick={onConfirmChangeStatus}>
											{actionName}
										</Button>
									</Col>
								</div>
							</Col>
						</Row>
					</div>
				</Spin>

				{isShowModalConfirm && (
					<ModalConfirmResetSecret
						onVisibleChange={handleModalConfirmVisibleChange}
						onSuccess={handleAppEditSuccess}
						handleAllowed={props.handleAllowed}
					/>
				)}
			</div>
		</>
	)
}
