import {ArrowLeftOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons"
import {Button, Form, Input, notification, Spin, Typography} from "antd"
import Text from "antd/lib/typography/Text"
import React, {useEffect, useState} from "react"
import {Link, useNavigate, useParams} from "react-router-dom"
import {MailTemplateApi} from "../../../../../Api/MailTemplateApi"
import {AccessDenied} from "../../../../../Component/AccessDenied"
import TinyMCE from "../../../../../Component/Custom/TinyMCE"
import DefaultLayout from "../../../../../Component/Layout/Default"

const { Title } = Typography

export const TemplateEmailUpdate = () => {
	const navigate = useNavigate()
	const { code, appCode } = useParams()
	const [isLoading, setIsLoading] = useState(false)
	const [templateCode, setTemplateCode] = useState("")
	const [notAllowedError, setNotAllowedError] = useState(false)

	const [form] = Form.useForm()

	useEffect(() => {
		const fetchMailTemplate = async () => {
			try {
				setIsLoading(true)
				const response = await MailTemplateApi.findMailTemplateByCode(code!, appCode!)
				const { data } = response
				form.setFieldsValue({ ...data })
				setTemplateCode(data.code)
				setIsLoading(false)
			} catch (err: any) {
				const { status } = err.response.data
				if (status === 403) {
					setNotAllowedError(true)
				} else {
					notification.error({
						message: err.message || "Có lỗi xảy ra trong lúc tải trang",
					})
				}

				setIsLoading(false)
			}
		}

		fetchMailTemplate()

		return () => {
			fetchMailTemplate()
		}
	}, [appCode, code, form])

	const onFinish = async (values: any) => {
		setIsLoading(true)
		try {
			await form.validateFields()
			await MailTemplateApi.updateMailTemplate(appCode!, code!, values)
			setIsLoading(false)
			navigate(`/apps/${appCode}/templates`)
			notification.success({ message: "Cập nhật thành công !" })
		} catch (err: any) {
			notification.error({ message: err.message || "Đã có lỗi xảy ra !" })
			setIsLoading(false)
		}
	}

	return (
		<>
			{notAllowedError && <AccessDenied />}
			{!notAllowedError && (
				<DefaultLayout title="Template - M29">
					<Spin spinning={isLoading}>
						<div className="main-content template">
							<div className="template-box template-add">
								<Link to={`/apps/${appCode}/templates`} className="template-add-leave">
									<ArrowLeftOutlined /> Trở về Danh Sách Template
								</Link>
								<Title level={5} className="template-add-title">
									Template Thư Mới
								</Title>
								<Form
									form={form}
									name="mail_template_edit"
									labelCol={{ span: 6 }}
									wrapperCol={{ span: 12 }}
									autoComplete="off"
									onFinish={onFinish}
								>
									<Form.Item
										className="template-add-leave"
										label={<span className="need-required">Mã Template:</span>}
									>
										<Text className="font-size-16">{templateCode}</Text>
									</Form.Item>
									<Form.Item
										className="template-add-leave"
										label={<span>Tên Template :</span>}
										name="name"
									>
										<Input autoFocus={true} placeholder="Type here..." />
									</Form.Item>
									<Form.Item
										className="template-add-leave"
										label={<span>Tiêu đề thư :</span>}
										name="subject"
									>
										<Input placeholder="Type here..." />
									</Form.Item>
									<Form.Item
										className="template-add-leave template-add-editor"
										label={<span>Nội dung thư :</span>}
										name="body"
									>
										<TinyMCE />
									</Form.Item>
									<div className="d-plex justify-end template-add-btn">
										<Button className="btn cancel" onClick={() => navigate(-1)}>
											<CloseOutlined /> Hủy Bỏ
										</Button>
										<Button className="btn" htmlType="submit">
											<CheckOutlined /> Xác Nhận
										</Button>
									</div>
								</Form>
							</div>
						</div>
					</Spin>
				</DefaultLayout>
			)}
		</>
	)
}
