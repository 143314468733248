import {Form, notification, Table, Tag, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {MAIL_STATUS} from "../../../../Util/Constants";
import lodash from "lodash";
import {AppApi} from "../../../../Api/AppApi";
import {useParams, useSearchParams} from "react-router-dom";
import AppMenu from "../AppMenu";
import DefaultLayout from "../../../../Component/Layout/Default";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {AppDefault, AppDomain} from "../../../../Domain/AppDomain";
import {AccessDenied} from "../../../../Component/AccessDenied";
import MailSendCriteria from "../../../../Criteria/MailSendCriteria";
import moment from "moment";
import Paragraph from "antd/lib/typography/Paragraph";
import {MailSendFilter} from "./MailSendFilter";


export const MailSend = () => {
    const {appCode, mailRequestId} = useParams()
    const [mailSends, setMailSends] = useState([])
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState( 25)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const [notAllowedError, setNotAllowedError] = useState<boolean | null>()
    const [app, setApp] = useState<AppDomain>(AppDefault)
    const {xs} = useBreakpoint()
    const [searchParams, setSearchParams] = useSearchParams()
    const [form] = Form.useForm();
    const mailStatuses = lodash.map(lodash.mapValues(MAIL_STATUS, (v, k) => {
        return {
            ...v,
            code: k
        }
    }))
    const columns = [
        {
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
            render: (subject: any) => {
                return <Tooltip title={subject}><div className={'truncate'}>{subject}</div></Tooltip>
            },
            width: 200
        },
        {
            title: "From",
            dataIndex: "sender",
            key: "sender",
            width: 200,
        },
        {
            title: "To",
            dataIndex: "to",
            key: "to",
            width: 350,
            render: (to: any) => {
                if (to.length === 1) {
                    return to
                }
                return <Tooltip title={to.join(', ')}>
                    <div className={'width-100pc'}>{lodash.first(to)} và {lodash.size(to) - 1} người khác</div>
                </Tooltip>
            }
        },
        {
            title: "Thời gian gửi",
            key: "sentAt",
            dataIndex: "sentAt",
            width: 180,
            render: (timestamp: any) => {
                const timestampFormatted = moment(timestamp).format("HH:mm:ss DD/MM/YYYY")
                const toolTipElement = <p
                    className={`'truncate-multiline-1`}>{timestamp ? timestampFormatted : '"Thời gian request"'}</p>
                return (
                    <Paragraph ellipsis={{tooltip: toolTipElement}} className="mg-bt-0">
                        {timestamp ? timestampFormatted : "--"}
                    </Paragraph>
                )
            },
        },
        {
            title: "Trạng thái",
            key: "status",
            width: 90,
            render: (row: any) => {
                return<Tag color={lodash.get(MAIL_STATUS, `${row.status}.color`)} className={'rounded-md'}>{row.status}</Tag>}
        },
        {
            title: "Lí do thất bại",
            key: "failedReason",
            dataIndex: "failedReason",
            width: 120,
            render: (failedReason: any) => {
                if(failedReason)
                return <Tooltip title={failedReason}><div className={'truncate'}>{failedReason}</div></Tooltip>
                else return <span className={'truncate'}>---</span>
            },
        }
    ]

    const handleChangePage = (page: any) => {
        setCurrentPage(page)
        const params = Object.fromEntries(searchParams.entries())
        params.offset = ((page - 1) * pageSize).toString()
        params.limit = pageSize.toString()
        setSearchParams({...params})
    }

    useEffect(() => {
        setPageSize(searchParams.has('limit') ? Number(searchParams.get('limit')) : pageSize)
        setCurrentPage(searchParams.has('offset') ? Number(searchParams.get('offset')) + 1 : 1)
    }, [pageSize, searchParams])

    useEffect(() => {
        const fetchAppDetail = async () => {
            setLoading(true)
            try {
                const response = await AppApi.getAppByCode(appCode!)
                setApp(response.data)
                setLoading(false)
            } catch (err: any) {
                const {status} = err.response
                if (status === 403) {
                    setNotAllowedError(true)
                }
                if (lodash.get(err.response, 'status') !== 401) {
                    notification.error({message: lodash.get(err.response, 'title') || "Đã có lỗi xảy ra !"})
                }
                setLoading(false)
            }
        }
        fetchAppDetail();
    }, [appCode])


    useEffect(() => {
        let offset:any = searchParams.get('offset') || 0;
        let limit:any = pageSize;
        let from:any = searchParams.get('from');
        let recipient:any = searchParams.get('recipient');
        let subject:any = searchParams.get('subject');
        let timestampFrom:any = searchParams.get("timestampFrom");
        let timestampTo:any = searchParams.get("timestampTo");
        let status:any = searchParams.get('status')
        let sort = searchParams.get("sort") || "createdAt:desc"
        let filter:MailSendCriteria = {offset, limit, sort, timestampFrom, timestampTo,from,recipient,subject,status}
        const fetchMailSends = (params: MailSendCriteria) => {
            setLoading(true)
            AppApi.filterMailSends(appCode!, mailRequestId!, params)
                .then(response => {
                    let currenPage = parseInt(lodash.get(response, "headers.x-page-number")) + 1
                    let total = parseInt(lodash.get(response, "headers.x-total-count"))
                    let pageSize = parseInt(lodash.get(response, "headers.x-page-size"))
                    setPageSize(pageSize)
                    setTotal(total)
                    setCurrentPage(currenPage)
                    setMailSends(response.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
        fetchMailSends(filter)
    }, [appCode, mailRequestId, pageSize, searchParams,form]);


    if (notAllowedError) {
        return <AccessDenied/>
    }

    return (
        <div className="app__detail">
            <DefaultLayout
                title={`${!xs ? (app.name && app.name.length > 30 ? `${app.name.substring(0, 16)}` : app.name) + " - " : ""}Chi tiết ứng dụng - M29`}
            >
                <div className="main-content template">
                    <AppMenu appCode={appCode} currentKey={'MAIL-REQUEST'}/>
                    <div className="template-box overflow">
                        <div className="template-box-table">
                            <div className="template-box-table-head">
                                <Typography.Title level={5}>
                                    Danh Sách Mail Đã Gửi
                                </Typography.Title>
                                <div className="line-divider"/>
                            </div>
                        </div>

                        <div className="mail-send">
                            <div>
                                <MailSendFilter mailStatuses={mailStatuses}/>
                                <div className={'mg-bt-10'}>Tổng số bản ghi: {total}</div>
                                <Table
                                    className={'table-responsive'}
                                    loading={loading}
                                    columns={columns}
                                    dataSource={mailSends}
                                    pagination={{
                                        hideOnSinglePage: true,
                                        current: currentPage,
                                        total: total,
                                        pageSize: pageSize,
                                        onChange:handleChangePage,
                                    }}
                                    rowKey={() => lodash.uniqueId('mail_send_')}
                                    scroll={{x:800,y:600}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultLayout>
        </div>
    )
}
