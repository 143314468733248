import {notification} from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import React, {useEffect, useState} from "react"
import {useLocation, useParams} from "react-router-dom"
import {AppApi} from "../../../Api/AppApi"
import {AccessDenied} from "../../../Component/AccessDenied"
import DefaultLayout from "../../../Component/Layout/Default"
import {AppDefault, AppDomain} from "../../../Domain/AppDomain"
import AppMenu from "./AppMenu"
import AppOverview from "./AppOverview"
import lodash from 'lodash'

export const AppDetail: React.FC = () => {
	const { appCode } = useParams()
	const [notAllowedError, setNotAllowedError] = useState<boolean | null>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [app, setApp] = useState<AppDomain>(AppDefault)
	const location = useLocation()
	const { xs } = useBreakpoint()

	useEffect(() => {
		const fetchAppDetail = async () => {
			setIsLoading(true)
			try {
				const response = await AppApi.getAppByCode(appCode!)
				setApp(response.data)
				setIsLoading(false)
			} catch (err: any) {
				const { status } = err.response
				if (status === 403) {
					setNotAllowedError(true)
				}
				if(lodash.get(err.response, 'status') !== 401){
					notification.error({ message: lodash.get(err.response, 'title') || "Đã có lỗi xảy ra !" })
				}
				setIsLoading(false)
			}
		}

		fetchAppDetail()

		return () => {
			setNotAllowedError(null)
		}
	}, [appCode, location])

	if (notAllowedError) {
		return <AccessDenied />
	}

	return (
		<div className="app__detail">
			<DefaultLayout
				title={`${!xs ? (app.name && app.name.length > 30 ? `${app.name.substring(0, 16)}` : app.name) + " - " : ""}Chi tiết ứng dụng - M29`}
			>
				<div className="main-content template">
					<AppMenu appCode={appCode} currentKey={"OVER_VIEW"} />
					<AppOverview app={app} loading={isLoading} handleAllowed={(val: boolean) => setNotAllowedError(val)} />
				</div>
			</DefaultLayout>
		</div>
	)
}
