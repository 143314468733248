import React from "react"
import { AppDomain } from "../../../Domain/AppDomain"
import { AppInfo } from "./AppInfo"
import UserList from "./UserList"

interface Props {
	app: AppDomain
	loading: boolean
	handleAllowed: (val: boolean) => void
}

const AppOverview: React.FC<Props> = ({ app, loading, handleAllowed }) => {
	return (
		<div className="template-box">
			<AppInfo app={app} loading={loading} handleAllowed={handleAllowed}/>
			<UserList />
		</div>
	)
}

export default AppOverview
