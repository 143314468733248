import lodash from "lodash"
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { AdminAppApi } from "../../../Api/AdminAppApi"
import DefaultLayout from "../../../Component/Layout/Default"
import AppCriteria from "../../../Criteria/AppCriteria"
import { AppDomain } from "../../../Domain/AppDomain"
import Heading from "../../App/Heading"
import DataList from "./DataList"
import Filter from "./Filter"

const AdminApp = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [appList, setAppList] = useState<Array<AppDomain>>([])
	const [isLoading, setIsLoading] = useState(false)

	const [pageSize, setPageSize] = useState(Number(searchParams.get("limit")) || 25)
	const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 1)
	const [total, setTotal] = useState(0)

	useEffect(() => {
		const name = searchParams.get("name") || ""
		const offset = searchParams.get("offset") || 0
		const limit = pageSize
		const sort = searchParams.get("sort") || "createdAt:desc"
		const status = searchParams.get("status") || ""
		const isOwner = true

		const filter: AppCriteria = { name, offset, limit, sort, status, isOwner }

		const fetchApps = async () => {
			try {
				setIsLoading(true)

				const response: any = await AdminAppApi.filter(filter)
				const { data } = response
				const currenPage = parseInt(lodash.get(response, "headers.x-page-number"))
				const total = parseInt(lodash.get(response, "headers.x-total-count"))
				const pageSize = parseInt(lodash.get(response, "headers.x-page-size"))
				setPageSize(pageSize)

				setAppList(data)
				setCurrentPage(currenPage + 1)
				setTotal(total)
				setIsLoading(false)
			} catch (err: any) {
				setIsLoading(false)
			}
		}

		fetchApps()
	}, [searchParams, setCurrentPage, pageSize])

	const handleFilter = (params: any) => {
		const keyOfItemEmpty = Object.keys(params).filter((key) => lodash.isEmpty(params[key]))
		setSearchParams({
			...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
			limit: pageSize.toString(),
			offset: "0",
		})
	}

	const handleChangePage = (page: number, pageSize: number) => {
		setCurrentPage(page)
		setPageSize(pageSize)

		const params: { [key: string]: any } = {
			page: page,
			limit: pageSize,
			offset: (page - 1) * pageSize,
			name: searchParams.get("name") || "",
			status: searchParams.get("status") || "",
		}

		const keyOfItemEmpty = Object.keys(params).filter(
			(key) => lodash.isEmpty(params[key]) && !lodash.includes(["page", "limit", "offset"], key)
		)
		setSearchParams({
			...lodash.mapValues(lodash.omit(params, keyOfItemEmpty), lodash.method("toString")),
		})
	}

	return (
		<>
			<DefaultLayout title={"Danh sách tất cả ứng dụng - M29"}>
				<div className="app">
					<Heading />
					<Filter totalApp={total} onFilter={handleFilter} />
					<DataList
						data={appList}
						total={total}
						pageSize={pageSize}
						isLoading={isLoading}
						currentPage={currentPage}
						onChangePage={handleChangePage}
					/>
				</div>
			</DefaultLayout>
		</>
	)
}

export default AdminApp
