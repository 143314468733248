import React, { useState } from "react"
import { Button, Form, Input, Modal, notification, Upload } from "antd"
import {
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	FormOutlined,
	LoadingOutlined,
	PlusOutlined,
} from "@ant-design/icons"
import { RcFile } from "antd/lib/upload"
import { AppApi } from "../../Api/AppApi"
import { AppDomain } from "../../Domain/AppDomain"

const { Item } = Form

export interface Props {
	onVisibleChange: (visible: boolean) => void
	onSuccess: (app: AppDomain) => void
}

const ModalAppCreate: React.FC<Props> = (props) => {
	const [isLoadingUpload, setIsLoadingUpload] = useState(false)
	const [imageUrl, setImageUrl] = useState<string | null>()
	const [form] = Form.useForm()
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
	const [isLoading, setIsLoading] = useState(false)

	const beforeUpload = (file: RcFile) => {
		if (!file.type.startsWith("image")) {
			notification.error({
				message: "Chỉ cho phép upload file ảnh",
			})
			return false
		}

		setIsLoadingUpload(true)

		AppApi.uploadAvatar(file)
			.then((response) => {
				const avatar = response.data
				setImageUrl(avatar)
				setSubmitButtonDisabled(false)
				form.setFieldsValue({ avatar })
			})
			.catch((error) => {
				notification.error({
					message: error.message,
				})
			})
			.finally(() => {
				setIsLoadingUpload(false)
			})

		return false
	}

	const uploadButton = (
		<div>
			{isLoadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	)

	const onFinish = async () => {
		setIsLoading(true)
		form.validateFields()
			.then((values) => {
				return AppApi.createApp({
					...values,
					avatar: form.getFieldValue("avatar"),
				})
			})
			.then((response) => {
				notification.success({
					message: "Tạo app thành công",
				})

				props.onSuccess(response.data)
			})
			.catch(() => {
				notification.error({
					message: "Có lỗi. Vui lòng kiểm tra lại thông tin",
				})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const onFieldsChangeHandle = () => {
		setSubmitButtonDisabled(form.getFieldsError().some((field) => field.errors.length > 0))
	}

	const footerCustom = [
		<Button
			key="cancel"
			htmlType="button"
			icon={<CloseOutlined />}
			onClick={() => props.onVisibleChange(false)}
			className="btn cancel"
		>
			Hủy Bỏ
		</Button>,
		<Button
			form="form"
			key="submit"
			htmlType="submit"
			icon={<CheckOutlined />}
			disabled={submitButtonDisabled}
			className={`btn ${submitButtonDisabled ? "hover-dis" : ""}`}
			loading={isLoading}
		>
			Xác nhận
		</Button>,
	]

	const handleDeleteAvatar = (e: any) => {
		e.stopPropagation()
		onFieldsChangeHandle()
		form.setFieldsValue({ ...props, avatar: "" })
		setImageUrl("")
	}

	return (
		<div>
			<Modal
				title={"Tạo ứng dụng"}
				visible={true}
				onCancel={() => props.onVisibleChange(false)}
				width={700}
				okText="Xác nhận"
				cancelText="Huỷ bỏ"
				footer={footerCustom}
				className="app-modal"
			>
				<Form
					form={form}
					onFinish={onFinish}
					name="form"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					autoComplete="off"
					initialValues={{ modifier: "public" }}
					onFieldsChange={onFieldsChangeHandle}
				>
					<Item
						label={<span className="need-required">Tên ứng dụng :</span>}
						name="name"
						rules={[
							{
								required: true,
								message: "Vui lòng nhập tên ứng dụng",
							},
							{
								whitespace: true,
								message: "Tên ứng dụng không hợp lệ",
							},
						]}
					>
						<Input autoFocus={true} className="py-5 px-12" placeholder="Nhập tên ứng dụng" />
					</Item>
					<Item label={<span>Ảnh</span>}>
						<Upload
							accept={"image/*"}
							listType="picture-card"
							className="avatar-uploader "
							showUploadList={false}
							beforeUpload={beforeUpload}
						>
							{isLoadingUpload ? (
								<LoadingOutlined />
							) : imageUrl ? (
								<>
									<div className="info">
										<img src={imageUrl} alt="avatar" className="pd-8 image-auto" />
									</div>
									<div className="upload-action__btn">
										<FormOutlined className="mg-r-12" />
										<DeleteOutlined onClick={(e) => handleDeleteAvatar(e)} />
									</div>
								</>
							) : (
								uploadButton
							)}
						</Upload>
					</Item>
					<Item label={<span>Mô tả</span>} name="description">
						<Input.TextArea className="py-5 px-12" placeholder="Nhập mô tả..." />
					</Item>
				</Form>
			</Modal>
		</div>
	)
}

export default ModalAppCreate
