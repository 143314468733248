import { AppUpdateStatusCommand } from "../Command/AppUpdateCommand"
import AppCriteria from "../Criteria/AppCriteria"
import SenderCriteria from "../Criteria/SenderCriteria"
import apiClient from "../Util/ApiClient"

export class AdminAppApi {
	static filter(criteria: AppCriteria) {
		return apiClient.get("/admin/apps", {
			params: criteria,
		})
	}

	static getAppByCode(code: string) {
		return apiClient.get(`/admin/apps/${code}`)
	}

	static updateStatus = (code: string, status: AppUpdateStatusCommand) => {
		return apiClient.patch(`/admin/apps/${code}/status`, status)
	}

	static getUsersInApp(code: string) {
		return apiClient.get(`/admin/apps/${code}/members`)
	}

	static filterSenders(code: string, criteria: SenderCriteria) {
		return apiClient.get(`/admin/apps/${code}/senders`, { params: criteria })
	}

	static addSender(code: string, email: any) {
		return apiClient.post(`/admin/apps/${code}/senders`, email)
	}

	static verifyMailByUser(appCode: string, code: string) {
		return apiClient.post(`/admin/apps/${appCode}/senders/${code}/verify`)
	}

	static resendVerification(appCode: string, code: string) {
		return apiClient.post(`/admin/apps/${appCode}/senders/${code}/resend-verification`)
	}
}
