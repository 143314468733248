import CreateAppCommand from "../Command/CreateAppCommand"
import AppCriteria from "../Criteria/AppCriteria"
import apiClient from "../Util/ApiClient"
import {RcFile} from "antd/lib/upload"
import {AppUpdateCommand, AppUpdateStatusCommand} from "../Command/AppUpdateCommand"
import MemberCriteria from "../Criteria/MemberCriteria"
import MailRequestCriteria from "../Criteria/MailRequestCriteria"
import MailSendCriteria from "../Criteria/MailSendCriteria";

export class AppApi {
	static filter(criteria: AppCriteria) {
		return apiClient.get("/apps", {
			params: criteria,
		})
	}

	static getAppByCode(code: string) {
		return apiClient.get(`/apps/${code}`)
	}

	static createApp = (command: CreateAppCommand) => {
		return apiClient.post("/apps", command)
	}

	static uploadAvatar = (file: File | RcFile) => {
		const formData = new FormData()
		formData.append("avatar", file)
		return apiClient.post("/application-avatars", formData)
	}

	static update = (code: string, command: AppUpdateCommand) => {
		return apiClient.patch(`/apps/${code}`, command)
	}

	static getTemplates(appCode: string) {
		return apiClient.get(`/apps${appCode}/mail-templates`)
	}

	static findMailTemplate(appCode: string, code: string) {
		return apiClient.get(`/apps${appCode}/mail-templates/${code}`)
	}

	static resetSecretApp = (code: string) => {
		return apiClient.post(`/apps/${code}/reset-secret`)
	}

	static updateStatus = (code: string, status: AppUpdateStatusCommand) => {
		return apiClient.patch(`/apps/${code}/status`, status)
	}

	static filterMember(code: string, criteria: MemberCriteria) {
		return apiClient.get(`/apps/${code}/members`, {
			params: criteria,
		})
	}

	static addMember = (code: string, email: any) => {
		return apiClient.post(`/apps/${code}/members`, email)
	}

	static removeMember = (code: string, userId: string) => {
		return apiClient.delete(`/apps/${code}/members/${userId}`)
	}

	static filterMailRequest(code: string, criteria: MailRequestCriteria) {
		return apiClient.get(`/apps/${code}/mail-requests`, {
			params: criteria,
		})
	}

	static filterMailSends(appCode: string, mailRequestId: string, criteria: MailSendCriteria) {
		return apiClient.get(`/apps/${appCode}/mail-requests/${mailRequestId}/email-messages`, {
			params: criteria,
		})
	}

}
