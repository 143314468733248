import { RedoOutlined, SearchOutlined } from "@ant-design/icons"
import { Badge, Button, Col, Form, Input, Row, Select, Typography } from "antd"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import WhiteBox from "../../../Component/Custom/WhiteBox"
import { APP_STATUS } from "../../../Util/Constants"
import { getCleanFormFilter } from "../../../Util/Utils"

const { Option } = Select
const { Title } = Typography
const { Item } = Form

interface FilterProps {
	totalApp: number
	showModalAppCreate?: () => void
	onFilter: (params: any) => void
}

const Filter: React.FC<FilterProps> = (props) => {
	const [searchParams] = useSearchParams()
	const [form] = Form.useForm()

	const getFormState = () => {
		let filter = getCleanFormFilter(form.getFieldsValue(true), ["isOwner"])

		return filter
	}

	useEffect(() => {
		form.setFieldsValue({ name: searchParams.get("name") || "", status: searchParams.get("status") || "" })
	}, [form, searchParams])

	const onHandleFilter = () => {
		props.onFilter(getFormState())
	}

	const clearFilter = () => {
		form.resetFields()
		props.onFilter({})
	}

	return (
		<WhiteBox className="pd-t-20 pd-bt-16 px-24 app-filter">
			<div className="mg-bt-20 flex justify-between items-center">
				<div className="flex gap-2 heading">
					<Title level={5}>Danh sách ứng dụng</Title>
					<Badge className="total-count" count={props.totalApp} overflowCount={10000} />
				</div>
				<div className="line-divider" />
			</div>
			<Form
				layout="horizontal"
				className="app-filter__form"
				form={form}
				onFinish={onHandleFilter}
				name="form_filter"
				initialValues={{
					name: "",
					status: "",
					isOwner: [],
				}}
				labelAlign="left"
			>
				<Row align="middle" gutter={[32, 12]}>
					<Col xs={24} sm={12} md={12} xl={6} xxl={6}>
						<Item
							label={<span className={`app-label`}>Tên App:</span>}
							name="name"
							className="app-filter__form--name width-100pc"
						>
							<Input
								placeholder="Tìm tên theo app"
								suffix={<SearchOutlined onClick={onHandleFilter} />}
							/>
						</Item>
					</Col>
					<Col xs={24} sm={12} md={12} xl={6} xxl={5} className="">
						<Item
							label={<span className="app-label">Trạng thái:</span>}
							className="app-filter__form--status width-100pc"
							name="status"
						>
							<Select className="" onChange={onHandleFilter}>
								<Option className="status-item" value="">
									Tất cả
								</Option>
								{Object.keys(APP_STATUS).map((key) => (
									<Option key={key} className="status-item" value={key}>
										{APP_STATUS[key].name}
									</Option>
								))}
							</Select>
						</Item>
					</Col>

					<Col
						xs={{ span: 12, push: 12 }}
						sm={{ span: 12, push: 12 }}
						xl={{ span: 12, push: 0 }}
						xxl={13}
						className={`app-filter__form-admin--search`}
					>
						<Item>
							<Button className="btn" icon={<SearchOutlined />} form={"form_filter"} htmlType="submit">
								Tìm kiếm
							</Button>
						</Item>
					</Col>
					<Col
						xs={{ span: 12, pull: 12 }}
						sm={{ span: 12, pull: 12 }}
						xl={{ span: 24, pull: 0 }}
						className="app-filter__form-admin--clear"
					>
						<span className="clear" onClick={clearFilter}>
							<RedoOutlined /> Làm mới bộ lọc
						</span>
					</Col>
				</Row>
			</Form>
		</WhiteBox>
	)
}

export default Filter
