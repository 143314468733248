import React from "react"
import { Link } from "react-router-dom"
import DefaultLayout from "../Layout/Default"
import imageError from "../../resources/images/image-403.png"
import { Button } from "antd"
export const AccessDenied = () => {
	return (
		<DefaultLayout>
			<div className="error-page flex items-center flex-col">
				<div>
					<img src={imageError} alt="" />
				</div>

				<div className="error-content">
					<h3>ERROR 403!</h3>
					<p>
						Có lỗi xảy ra! Bạn không thể truy cập vào địa chỉ này, vui lòng liên hệ bộ phận CSKH để được hỗ
						trợ!
					</p>
					<Link to={"/"}>
						<Button className="btn">Trở Về Trang Chủ</Button>
					</Link>
				</div>
			</div>
		</DefaultLayout>
	)
}
